import React, { useState, useEffect, useRef } from 'react';
import MainFooter from '../Components/MainFooter'
import { AutoComplete, Space } from 'antd'
import { Switch } from 'antd';
import { Link } from 'react-router-dom';
import SecondaryHeader from '../Components/SecondaryHeader';
import CompareModal from '../Components/CompareModal';
import { Button, Modal } from 'antd';


const mockVal = (str, repeat = 1) => ({
  value: str.repeat(repeat),
});
const isWheelChairFilter = (checked) => {
  console.log(`switch to ${checked}`);
};

function Activities() {
  //menu-functions
  const [open, setOpen] = useState(false);
  const [selectedTrips, setSelectedTrips] = useState([]);
  const [menuOpen, setMenuOpen] = useState(false);
  const [options, setOptions] = useState([]);
  const [allActivities, setAllActivities] = useState([
    {
      id: 1,
      title: 'Private Aerial Tour of Curaçao',
      description: "Experience Curaçao's underwater world up close with our guided , perfect for all skill levels.",
      location: 'Willemstad',
      review: '4.0',
      price: '234.00',
      image: '../../assets/image//private-jet.png',

      dothis: [
        'Enjoy stunning aerial views of Curaçao.',
        'Capture unique and breathtaking photos.',
        'Experience personalized service with a private plane.',
        'Discover hidden gems with an expert guide.',
        'Perfect for celebrating special occasions.'],

      suitablefor: {
        group: false,
        single: true,
        children: true
      },
      includeditems: {
        photos: true,
        video: false,
        water: false
      },
      presentitems: {
        requiredequipment: true,
        chairs: false,
        wc: true
      },
      availablelanguages: {
        english: true,
        dutch: false,
        papiamento: true,
        spanish: false
      },
      whattobring: [
        'Comfortable Shoes',
        'Weather appropriate clothing'
      ],
    },


    {
      id: 2, title: 'Curaçao Guided ',
      location: 'Spanish Water Bay', review: '4.8', price: '150.00',
      description: "Experience Curaçao's underwater world up close with our guided , perfect for all skill levels.", image: '../../assets/image//Screenshot 2024-01-27 at 22.28.17.png', dothis: [
        'Enjoy stunning aerial views of Curaçao.',
        'Capture unique and breathtaking photos.',
        'Experience personalized service with a private plane.',
        'Discover hidden gems with an expert guide.',
        'Perfect for celebrating special occasions.'],
      suitablefor: {
        group: false,
        single: true,
        children: true
      },
      includeditems: {
        photos: true,
        video: false,
        water: false
      },
      presentitems: {
        requiredequipment: true,
        chairs: false,
        wc: true
      },
      availablelanguages: {
        english: true,
        dutch: false,
        papiamento: true,
        spanish: false
      },
      whattobring: [
        'Comfortable Shoes',
        'Weather appropriate clothing'
      ],
    },
    {
      id: 3, title: 'Swim with Sea turtles', location: 'Klein Curaçao', review: '4.7', price: '200.00', description: "Experience Curaçao's underwater world up close with our guided , perfect for all skill levels.", image: '../../assets/image//Screenshot 2024-01-30 at 15.35.24.png',
      dothis: [
        'Enjoy stunning aerial views of Curaçao.',
        'Capture unique and breathtaking photos.',
        'Experience personalized service with a private plane.',
        'Discover hidden gems with an expert guide.',
        'Perfect for celebrating special occasions.'],
      suitablefor: {
        group: false,
        single: true,
        children: true
      },
      includeditems: {
        photos: true,
        video: false,
        water: false
      },
      presentitems: {
        requiredequipment: true,
        chairs: false,
        wc: true
      },
      availablelanguages: {
        english: true,
        dutch: false,
        papiamento: true,
        spanish: false
      },
      whattobring: [
        'Comfortable Shoes',
        'Weather appropriate clothing'
      ],
    },
    {
      id: 4, title: 'Private Aerial Tour of Curaçao', location: 'Willemstad', review: '4.0', price: '234.00', description: "Experience Curaçao's underwater world up close with our guided , perfect for all skill levels.", image: '../../assets/image//private-jet.png', dothis: [
        'Enjoy stunning aerial views of Curaçao.',
        'Capture unique and breathtaking photos.',
        'Experience personalized service with a private plane.',
        'Discover hidden gems with an expert guide.',
        'Perfect for celebrating special occasions.'],
    },
    {
      id: 5, title: 'Curaçao Guided ', location: 'Spanish Water Bay', review: '4.8', price: '150.00', image: '../../assets/image//Screenshot 2024-01-27 at 22.28.17.png', dothis: [
        'Enjoy stunning aerial views of Curaçao.',
        'Capture unique and breathtaking photos.',
        'Experience personalized service with a private plane.',
        'Discover hidden gems with an expert guide.',
        'Perfect for celebrating special occasions.'],
    },
    {
      id: 6, title: 'Swim with Sea turtles', location: 'Klein Curaçao', review: '4.7', price: '200.00', image: '../../assets/image//Screenshot 2024-01-30 at 15.35.24.png', dothis: [
        'Enjoy stunning aerial views of Curaçao.',
        'Capture unique and breathtaking photos.',
        'Experience personalized service with a private plane.',
        'Discover hidden gems with an expert guide.',
        'Perfect for celebrating special occasions.'],
    },

  ]);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };
  //menu-functions

  //search-function

  const [anotherOptions, setAnotherOptions] = useState([]);
  const getPanelValue = (searchText) => {
    if (!searchText) return [];
    return allActivities
      .filter(activity => activity.title.toLowerCase().includes(searchText.toLowerCase()))
      .map(activity => ({ value: activity.title }));
  };
  //search function


  // Likebuttonfunction
  const LikeButton = ({ id }) => {
    const [isFilled, setIsFilled] = useState(false);

    const handleClick = (e) => {
      e.preventDefault();
      setIsFilled(!isFilled);
    };
    return (
      <div
        className='bg-white w-8 h-8 rounded-full absolute top-1 right-1 flex justify-center items-center z-10'
        onClick={handleClick}
        style={{ cursor: 'pointer' }}
      >
        <svg
          width="18"
          height="15"
          viewBox="0 0 15 13"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M7.50012 12.2822L1.64353 6.97733C-1.53939 3.7944 3.13951 -2.31681 7.50012 2.62733C11.8607 -2.31681 16.5184 3.81562 13.3567 6.97733L7.50012 12.2822Z"
            stroke="#F0B059"
            strokeWidth="1.06098"
            strokeLinecap="round"
            strokeLinejoin="round"
            fill={isFilled ? "orange" : "none"}
          />
        </svg>
      </div>
    );
  };
  // Likebuttonfunction
  //filter and sort modal
  const [filterModal, setFilterModal] = useState(false);
  const [sortModal, setSortModal] = useState(false);
  const filterModalRef = useRef();
  const sortModalRef = useRef();

  const [filters, setFilters] = useState({
    priceMin: '',
    priceMax: '',
    category: '',
    language: '',
    groupSizeMin: '',
    durationMin: '',
    durationMax: '',
    timeMin: '',
    timeMax: '',
    wheelChair: false,
  });

  const [sortCriteria, setSortCriteria] = useState({
    byPrice: '',
    popularity: '',
    duration: '',
  });

  const openFilterModal = () => {
    setFilterModal(!filterModal);
  }

  const openSortModal = () => {
    setSortModal(!sortModal);
  }

  const handleClickOutside = (event) => {
    if (filterModalRef.current && !filterModalRef.current.contains(event.target)) {
      setFilterModal(false);
    }
    if (sortModalRef.current && !sortModalRef.current.contains(event.target)) {
      setSortModal(false);
    }
  }

  useEffect(() => {
    if (filterModal || sortModal) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [filterModal, sortModal]);

  const applyFilters = () => {
    return allActivities.filter(activity => {
      const priceMin = filters.priceMin === '' || activity.price >= filters.priceMin;
      const priceMax = filters.priceMax === '' || activity.price <= filters.priceMax;
      const category = filters.category === '' || activity.category === filters.category;
      const language = filters.language === '' || activity.language === filters.language;
      const groupSizeMin = filters.groupSizeMin === '' || activity.groupSize >= filters.groupSizeMin;
      const durationMin = filters.durationMin === '' || activity.duration >= filters.durationMin;
      const durationMax = filters.durationMax === '' || activity.duration <= filters.durationMax;
      const timeMin = filters.timeMin === '' || activity.time >= filters.timeMin;
      const timeMax = filters.timeMax === '' || activity.time <= filters.timeMax;
      const wheelChair = !filters.wheelChair || activity.wheelChair;

      return priceMin && priceMax && category && language && groupSizeMin && durationMin && durationMax && timeMin && timeMax && wheelChair;
    });
  };

  const applySort = (filteredActivities) => {
    return filteredActivities.sort((a, b) => {
      if (sortCriteria.byPrice) {
        return sortCriteria.byPrice === 'Low to High' ? a.price - b.price : b.price - a.price;
      }
      if (sortCriteria.popularity) {
        return sortCriteria.popularity === 'Low to High' ? a.review - b.review : b.review - a.review;
      }
      if (sortCriteria.duration) {
        return sortCriteria.duration === 'Short to Long' ? a.duration - b.duration : b.duration - a.duration;
      }
      return 0;
    });
  };

  const handleCompareClick = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setOpen(true);
  };

  const handleTripSelection = (trip) => {
    setSelectedTrips((prevTrips) => {
      if (prevTrips.includes(trip)) {
        return prevTrips.filter((t) => t.id !== trip.id);
      } else if (prevTrips.length < 3) {
        return [...prevTrips, trip];
      }
      return prevTrips;
    });
  };


  const filteredActivities = applyFilters();
  const sortedActivities = applySort(filteredActivities);


  return (
    <main>
      <SecondaryHeader />


      

      <section className="p-5 md:p-8 flex flex-col md:flex-row gap-5">
      <div className=' border-2 border-theblue-400 md:w-1/3 h-56 rounded-2xl overflow-hidden relative'>
          <div className=' w-full h-full bg-gradient-to-tr from-black to-transparent opacity-40 absolute'></div>
          <img src="../../assets/carnival.webp" alt="" className='w-full h-full object-cover' />
          <a href='/partywithlocal' className=' bg-theblue-400 text-white p-3 absolute z-10 bottom-4 right-4 rounded-lg border border-theorange-400 shadow-lg pt-2 pb-2'>See More</a>
          <p className='  text-white absolute z-10 top-4 left-4 font-medium text-3xl  '> Local Experience</p>
        </div>
        
        
        <div className=' border-2 border-theblue-400 md:w-1/3 h-56 rounded-2xl overflow-hidden relative'>
          <div className=' w-full h-full bg-gradient-to-tr from-black to-transparent opacity-40 absolute'></div>
          <img src="../../assets/curacao-menu.jpg" alt="" className='w-full md:h-full object-cover' />
          <a href='/Kumindadikasexperience' className=' bg-theblue-400 text-white p-3 absolute z-10 bottom-4 right-4 rounded-lg border border-theorange-400 shadow-lg pt-2 pb-2'>See More</a>
          <p className='  text-white absolute z-10 top-4 left-4 font-medium text-3xl  '>Things to do</p>
        </div>


        <div className=' border-2 border-theblue-400 md:w-1/3 h-56 rounded-2xl overflow-hidden relative'>
          <div className=' w-full h-full bg-gradient-to-tr from-black to-transparent opacity-40 absolute'></div>
          <img src="../../assets/beaches.jpg" alt="" className='w-full h-full object-cover' />
          <a href='/beaches' className=' bg-theblue-400 text-white p-3 absolute z-10 bottom-4 right-4 rounded-lg border border-theorange-400 shadow-lg pt-2 pb-2'>See More</a>
          <p className='  text-white absolute z-10 top-4 left-4 font-medium text-3xl  '>Beaches</p>
        </div>
      </section>

      <section className=' pt-0 p-8 flex justify-between relative'>
        <Space
          direction="vertical"
          style={{
            width: '40%',
          }}
        >
          <AutoComplete
            options={anotherOptions}
            onSearch={(text) => setAnotherOptions(getPanelValue(text))}
            status="warning"
            placeholder='Search-activity'
            style={{
              width: '100%',
            }}
          />
        </Space>
        {/* <div className=' flex border-theorange-400 border rounded-md bg-white'>
          <input type="text" className=' bg-transparent border-none'/>
          <button className=' p-2 h-fit  gap-6 mybutton rounded-xl md:w-12 md:h-12 flex justify-center items-center' >

              <svg width="14" height="10" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M14.6122 1.78183L1.62524 14.7688" stroke="#FEFEFE" stroke-width="1.81243" stroke-miterlimit="10" stroke-linecap="round" />
                <path d="M15.1218 13.7082V3.03759C15.1229 2.80551 15.0779 2.57552 14.9896 2.36091C14.9012 2.1463 14.7712 1.95134 14.607 1.78728C14.4429 1.62322 14.2478 1.49333 14.0332 1.40512C13.8185 1.3169 13.5885 1.2721 13.3564 1.27332H2.68579" stroke="#FEFEFE" stroke-width="1.81243" stroke-linecap="round" stroke-linejoin="round" />
              </svg>
            </button>
        </div> */}
        <div className=' gap-3 flex'>
          <button className=' text-xs md:text-base border border-theblue-400 rounded-md text-theblue-400 pl-3 pr-3 flex justify-between gap-3 items-center p-2' onClick={openFilterModal}> <img src="../../assets/svg/filter.svg" alt="" className=' w-4 h-4 ' />Filter</button>
          <button className='text-xs md:text-base border border-theblue-400 rounded-md text-theblue-400 pl-3 pr-3 flex justify-between gap-3 items-center p-2' onClick={openSortModal}> <img src="../../assets/svg/sort.svg" alt="" className='w-4 h-4 ' />Sort</button>
        </div>


        {/* filter-modal */}
        {filterModal && (

          <div ref={filterModalRef} className='modal absolute md:absolute md:right-44 md:top-12 flex flex-col bg-white shadow-lg p-4 rounded-lg gap-3 z-20'>
            <h1 className='text-theblue-400 font-medium text-base '>Filter</h1>
            <form className='filter-form flex flex-col rounded-lg gap-3 z-20'>
              <div className='flex justify-between items-center gap-8 text-sm'>
                <p className='font-light w-1/3 text-sm' >Price-range</p>
                <div className='flex gap-3 justify-start items-center w-2/3'>
                  <input type="text" name="" id="" placeholder='min' className='border border-theblue-400 rounded-lg p-1 pl-3 w-16' value={filters.priceMin} onChange={(e) => setFilters({ ...filters, priceMin: e.target.value })} />
                  to
                  <input type="text" name="" id="" placeholder='max' className='border border-theblue-400 rounded-lg p-1 pl-3 w-16' value={filters.priceMax} onChange={(e) => setFilters({ ...filters, priceMax: e.target.value })} />
                </div>
              </div>

              <div className='flex justify-between items-center gap-8 text-sm'>
                <p className='font-light w-1/3 text-sm' >Category</p>
                <select name="" id="" defaultValue={''} className='border border-theblue-400 rounded-lg p-1 pl-3 w-2/3' value={filters.category} onChange={(e) => setFilters({ ...filters, category: e.target.value })} >
                  <option value="" disabled hidden className='font-light text-gray-500 italic'>Pick your Category</option>
                  <option value="Beach">Beach</option>
                  <option value="plain">Plain</option>
                  <option value="local">Local</option>
                </select>
              </div>

              <div className='flex justify-between items-center gap-8 text-sm' >
                <p className='font-light w-1/3 text-sm'>Language</p>
                <select name="language" id="language" defaultValue="" className='border border-theblue-400 rounded-lg p-1 pl-3 w-2/3' value={filters.language} onChange={(e) => setFilters({ ...filters, language: e.target.value })}>
                  <option value="" disabled hidden className='font-light text-gray-500 italic'>Pick your language</option>
                  <option value="english">English</option>
                  <option value="spanish">Spanish</option>
                  <option value="deustch">Deutsch</option>
                </select>
              </div>

              <div className='flex justify-between items-center gap-8 text-sm'>
                <p className='font-light w-1/3 text-sm'>Group size</p>
                <div className='flex gap-3 justify-start items-center w-2/3'>
                  <input type="text" name="" id="" placeholder='min' className='border border-theblue-400 rounded-lg p-1 pl-3 w-16' value={filters.groupSizeMin} onChange={(e) => setFilters({ ...filters, groupSizeMin: e.target.value })} />
                </div>
              </div>

              <div className='flex justify-between items-center gap-8 text-sm'>
                <p className='font-light w-1/3 text-sm'>Duration</p>
                <div className='flex gap-3 justify-start items-center w-2/3'>
                  <input type="text" name="" id="" placeholder='min' className='border border-theblue-400 rounded-lg p-1 pl-3 w-16' value={filters.durationMin} onChange={(e) => setFilters({ ...filters, durationMin: e.target.value })} />
                  to
                  <input type="text" name="" id="" placeholder='max' className='border border-theblue-400 rounded-lg p-1 pl-3 w-16' value={filters.durationMax} onChange={(e) => setFilters({ ...filters, durationMax: e.target.value })} />
                </div>
              </div>

              <div className='flex justify-between items-center gap-8 text-sm'>
                <p className='font-light w-1/3 text-sm'>Time of the day</p>
                <div className='flex gap-3 justify-start items-center w-2/3'>
                  <input type="time" name="" id="" placeholder='min' className='border border-theblue-400 rounded-lg p-1 pl-3 w-16' value={filters.timeMin} onChange={(e) => setFilters({ ...filters, timeMin: e.target.value })} />
                  to
                  <input type="time" name="" id="" placeholder='max' className='border border-theblue-400 rounded-lg p-1 pl-3 w-16' value={filters.timeMax} onChange={(e) => setFilters({ ...filters, timeMax: e.target.value })} />
                </div>
              </div>

              <div className='flex justify-between items-center gap-8 text-sm'>
                <p className='font-light w-1/3 '>Wheel Chair</p>
                <div className='flex gap-3 justify-start items-center w-2/3'>
                  <Switch checked={filters.wheelChair} onChange={(checked) => setFilters({ ...filters, wheelChair: checked })} />
                </div>
              </div>
              <button className=' bluebutton p-3 text-white' type='button' onClick={() => setFilterModal(false)}>Apply Filter</button>
            </form>
          </div>
        )}
        {/*end of  filter-modal */}
        {/* sort-modal */}
        {sortModal && (

          <div ref={sortModalRef} className='absolute right-16 top-40 md:absolute md:right-20 md:top-12 flex flex-col bg-white shadow-lg p-4 rounded-lg gap-3 z-20'>
            <h1 className='text-theblue-400 font-medium text-base'>Sort</h1>

            <div className='flex justify-between items-center gap-8 text-sm' >
              <p className='font-light w-1/3 text-sm'>By Price</p>
              <select className='border border-theblue-400 rounded-lg p-1 pl-3 w-2/3' value={sortCriteria.byPrice} onChange={(e) => setSortCriteria({ ...sortCriteria, byPrice: e.target.value })}>
                <option value=''>None</option>
                <option value='Low to High'>Low to High</option>
                <option value='High to Low'>High to Low</option>

              </select>
            </div>

            <div className='flex justify-between items-center gap-8 text-sm' >
              <p className='font-light w-1/3 text-sm'>Popularity</p>
              <select className='border border-theblue-400 rounded-lg p-1 pl-3 w-2/3' value={sortCriteria.popularity} onChange={(e) => setSortCriteria({ ...sortCriteria, popularity: e.target.value })}>
                <option value=''>None</option>
                <option value='Low to High'>Low to High</option>
                <option value='High to Low'>High to Low</option>

              </select>
            </div>

            <div className='flex justify-between items-center gap-8 text-sm' >
              <p className='font-light w-1/3 text-sm'>Duration</p>
              <select className='border border-theblue-400 rounded-lg p-1 pl-3 w-2/3' value={sortCriteria.duration} onChange={(e) => setSortCriteria({ ...sortCriteria, duration: e.target.value })}>
                <option value=''>None</option>
                <option value='Short to Long'>Short to Long</option>
                <option value='Long to Short'>Long to Short</option>

              </select>
            </div>

            <button className=' bluebutton p-3 text-white'>Apply Sort</button>
          </div>
        )}
        {/*end of  sort-modal */}





      </section>
      {/* main-section */}
      <section className="p-5 md:p-8 ">
        <h1 className="text-2xl text-theblue-400 md:text-3xl font-bold">All Activities</h1>

        <div className="activities-list w-full grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-4 gap-4 justify-start mt-5 pb-7 relative scroll-smooth flex-wrap">

          {sortedActivities.map(trip => (
            <div key={trip.id} className="flex-1 min-w-[300px]]">
              <div className="bg-white border-2 border-white opacity-95 rounded-3xl p-2 hover:border-2 hover:border-theblue-400 transition-all relative shadow-sm image-container" style={{ minHeight: '380px', }}>
                <div className='relative group'>
                  <Link to={`/trip/${trip.id}`} className="block">

                    <img className='w-full h-44 object-cover rounded-xl' src={trip.image} />
                    <LikeButton id={trip.id} />
                  </Link>
                </div>
                <div className='flex justify-between mt-2'>
                  <h2 className="text-md font-medium w-3/4 leading-tight m text-gray-700">{trip.title}</h2>
                  <div className='flex items-center gap-2'>
                    <p className='text-gray-500'>4.0</p>
                    <img src="../../assets/svg/star.svg" alt="" />
                  </div>
                </div>
                <p className="text-theorange-400 font-normal text-xs">{trip.location}</p>
                <div className='flex justify-between absolute bottom-5 w-11/12'>
                  <div className='flex flex-col justify-center gap-1'>
                    <div className='flex items-center gap-2 text-gray-500 font-normal text-xs text-left'>
                      <img src="../../assets/svg/3days.svg" alt="" />
                      3-days
                    </div>
                    <div className='flex items-center gap-2 text-gray-500 font-normal text-xs'>
                      <img src="../../assets/svg/bprice.svg" alt="" />
                      E-tickets available
                    </div>
                    <div className='flex justify-center items-center gap-2 text-gray-500 font-normal text-xs text-wrap lg:w-11/12 xl:w-full'>
                      <img src="../../assets/svg/24hr.svg" alt="" />
                      free 24 hours cancellation
                    </div>
                  </div>
                  <div className='flex flex-col text-right absolute bottom-0 right-0 gap-3'>
                    <button
                      className={`p-1 pl-3 pr-3 ${selectedTrips.includes(trip) ? 'bg-theorange-400' : 'bg-theblue-400'} text-slate-50 font-normal rounded-md text-xs border-none`}
                      onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        handleTripSelection(trip);
                      }}
                    >
                      {selectedTrips.includes(trip) ? 'Remove' : 'Compare'}
                    </button>
                    <p className='text-xl font-semibold text-theblue-400 leading-none'>$ {trip.price}</p>
                  </div>
                </div>
              </div>
            </div>
          ))}
          {selectedTrips.length > 0 && (
            <button
              className="fixed top-5 right-4 md:right-1/2 p-3 bg-theblue-400 text-slate-50 font-normal rounded-lg text-base border-none z-50 shadow-md "
              onClick={handleCompareClick}
            >
              Compare Selected Trips
            </button>
          )}
          {selectedTrips.length > 0 && (
            <Modal
              centered
              open={open}
              onOk={() => setOpen(false)}
              onCancel={() => setOpen(false)}
              width={1600}
              className="overflow-hidden"
              bodyStyle={{ overflowY: 'auto' }}
            >
              <CompareModal trips={selectedTrips} />
            </Modal>
          )}
        </div>
      </section>
      <MainFooter />
    </main>
  )
}

export default Activities
