import React from 'react';
// import BlueHeader from '../Components/BlueHeader';
import SecondaryHeader from '../Components/SecondaryHeader';
import MainFooter from '../Components/MainFooter';

export default function Disclaimer() {
  return (
    <main>
      <SecondaryHeader />
      <section className='p-5 md:p-8 '>
      <h1 className=' text-3xl font-bold text-theblue-400'>Disclaimer</h1>

        <p className='  font-extralight text-gray-500 text-sm md:text-lg mt-4'> The information provided on TheTripCuraçao's website is for general informational purposes only. While we strive to keep the information up to date and accurate, we make no representations or warranties of any kind, express or implied, about the completeness, accuracy, reliability, suitability, or availability with respect to the website or the information, products, services, or related graphics contained on the website for any purpose. Any reliance you place on such information is therefore strictly at your own risk.</p>

        <p className=' mt-4 font-extralight text-gray-500 text-sm md:text-lg'><span className='font-medium text-theblue-400'>Proprietary Rights:</span> TheTripCuraçao contains proprietary and confidential information protected by intellectual property laws. You are authorized to view and make a single copy of portions of its content for personal, non-commercial use. The content may not be sold, reproduced, or distributed without written permission. All trademarks, service marks, and logos are the property of their respective owners.</p>

        <p className=' mt-4 font-extralight text-gray-500 text-sm md:text-lg'><span className='font-medium text-theblue-400'>Submitted Content:</span> By submitting content to TheTripCuraçao, you grant a worldwide, royalty-free license to publish, display, modify, and distribute your content. You confirm that you have the necessary authority to grant this license.</p>

        <p className=' mt-4 font-extralight text-gray-500 text-sm md:text-lg'><span className='font-medium text-theblue-400'>Termination of Agreement:</span> These Terms will remain in effect perpetually unless terminated by either party. Provisions meant to continue in perpetuity will remain unaffected by termination.</p>

        <p className=' mt-4 font-extralight text-gray-500 text-sm md:text-lg'><span className='font-medium text-theblue-400'>Disclaimer of Warranties:</span> Your use of TheTripCuraçao is at your own risk. The services are provided "As Is" and "As Available." TheTripCuraçao makes no warranties, express or implied, regarding the operation of the website, information, content, or products, including but not limited to implied warranties of merchantability, fitness for a particular purpose, and non-infringement.</p>

        <p className=' mt-4 font-extralight text-gray-500 text-sm md:text-lg'><span className='font-medium text-theblue-400'>Limitation of Liability:</span> TheTripCuraçao and its affiliates will not be liable for any direct, indirect, incidental, consequential, or exemplary damages. This includes but is not limited to damages for loss of profits, business interruption, or loss of information. In jurisdictions that do not allow the exclusion of liability for consequential or incidental damages, liability is limited to the fullest extent permitted by law.</p>

        <p className=' mt-4 font-extralight text-gray-500 text-sm md:text-lg'><span className='font-medium text-theblue-400'>External Content:</span> TheTripCuraçao may contain links to third-party content and websites. TheTripCuraçao is not responsible for and does not endorse any third-party content or websites.</p>

        <p className=' mt-4 font-extralight text-gray-500 text-sm md:text-lg'><span className='font-medium text-theblue-400'>Jurisdiction:</span> You explicitly acknowledge and consent to submit to the personal and exclusive jurisdiction of the courts of Curaçao, as determined solely by TheTripCuraçao, for the resolution of any legal disputes arising from this agreement or related to your use of TheTripCuraçao. If a court of competent jurisdiction finds any provision of this agreement invalid, that provision will be removed, and the remaining terms will continue to be in effect.</p>

        <p className=' mt-4 font-extralight text-gray-500 text-sm md:text-lg'><span className='font-medium text-theblue-400'>Entire Agreement:</span> These Terms constitute the entire agreement between you and TheTripCuraçao. Additional terms and conditions may apply when using third-party content or services.</p>

        <p className=' mt-4 font-extralight text-gray-500 text-sm md:text-lg'><span className='font-medium text-theblue-400'>Changes to the Terms:</span> TheTripCuraçao can change these terms whenever they want, without telling you in advance. The new terms will apply from the day they are posted on the website. By continuing to use TheTripCuraçao after the terms are updated, you are agreeing to follow the new terms.</p>

        <p className=' mt-4 font-extralight text-gray-500 text-sm md:text-lg'><span className='font-medium text-theblue-400'>Feedback & Contact:</span> If you would like to provide feedback about our site or have a specific question, please contact us at <a href="mailto:info@thetripcuracao.com">info@thetripcuracao.com</a>. We would like to hear from you if certain information is outdated, if you have an addition, or if you cannot find sufficient (practically useful) information on a particular subject.</p>
      </section>
      <MainFooter/>
    </main>
  );
}
