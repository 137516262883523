import React from 'react'
import { useState } from 'react';
import MainFooter from '../Components/MainFooter';
import BlueHeader from '../Components/BlueHeader';

function ContactUs() {

  return (
    <main>
      <BlueHeader/>

      {/* hero section */}
      <section className=" overflow-hidden  -z-0 h-screen ">
        <div className="absolute inset-0 bg-gradient-to-r from-black to-transparent opacity-75"></div>
        <img src='../../assets/contact.jpg' alt="Hero" className="w-full h-full object-cover" />
        <div className=" w-full md:w-3/5 absolute inset-0 flex items-left flex-col justify-center p-8 gap-2">
          <p className='text-center md:text-left text-white italic font-light'>Contact Us</p>
          <h1 class="text-4xl text-center text-white md:text-6xl md:text-left font-extrabold ">Connect and Start Your < br className=' hidden md:block' />Curaçao Adventure</h1>
          <p className='text-center  md:text-left font-extralight text-white md:w-10/12'>We’d love to hear from you! Whether you have questions about our tours, need help planning your trip, or want to share feedback, our team is here to assist you.
          </p>
          <div className=' flex gap-2'>
            <input type="text" placeholder='Your Name' className='w-full pl-5 bg-white bg-opacity-5 backdrop-blur-sm p-4 md:w-1/2 placeholder:font-extralight placeholder:text-white border-2 border-theorange-400 rounded-lg placeholder:italic text-white' />
            <input type="text" placeholder='Mail Id' className='w-full pl-5 bg-white bg-opacity-5 backdrop-blur-sm p-4 md:w-1/2 placeholder:font-extralight placeholder:text-white border-2 border-theorange-400 rounded-lg placeholder:italic text-white' />
          </div>
          <input type="text" placeholder='Describe your request' className='w-full pl-5 bg-white bg-opacity-5 backdrop-blur-sm p-4 md:w-full placeholder:font-extralight placeholder:text-white border-2 border-theorange-400 rounded-lg placeholder:italic text-white' />
          <button type='submit' className=' mybutton text-white p-4 rounded-lg text-lg'>Send the request</button>
        </div>
      </section>
      {/* end of hero section */}

      <section className='p-5 md:p-8 '>
        <div className='flex flex-col md:flex-row gap-3'>
          <div className=' md:w-2/5'><h1 className=' text-2xl font-bold text-theblue-400'>How do I book a tour?</h1></div>
          <div className=' md:w-3/5'><p className=' font-extralight text-gray-500 text-sm md:text-lg'>You can book a tour directly through our website by selecting your desired tour and following the booking instructions. Alternatively, you can contact us via email or phone for assistance.</p>
          </div>
        </div>
        <div className=' w-full bg-theorange-400 h-[1px] mb-8 mt-8'></div>
        <div className='flex flex-col md:flex-row gap-3'>
          <div className=' md:w-2/5'><h1 className=' text-2xl font-bold text-theblue-400'>What is your cancellation policy?</h1></div>
          <div className=' md:w-3/5'><p className=' font-extralight text-gray-500 text-sm md:text-lg'>Cancellations made at least 24 hours before the tour date will receive a full refund. Cancellations made less than 24 hours before the tour date will incur a cancellation fee. Please refer to our cancellation policy page for detailed information.</p>
          </div>
        </div>
        <div className=' w-full bg-theorange-400 h-[1px] mb-8 mt-8'></div>
        <div className='flex flex-col md:flex-row gap-3'>
          <div className=' md:w-2/5'><h1 className=' text-2xl font-bold text-theblue-400'>How do I book a tour?</h1></div>
          <div className=' md:w-3/5'><p className=' font-extralight text-gray-500 text-sm md:text-lg'>You can book a tour directly through our website by selecting your desired tour and following the booking instructions. Alternatively, you can contact us via email or phone for assistance.</p>
          </div>
        </div>
        <div className=' w-full bg-theorange-400 h-[1px] mb-8 mt-8'></div>
        <div className='flex flex-col md:flex-row gap-3'>
          <div className=' md:w-2/5'><h1 className=' text-2xl font-bold text-theblue-400'>What is your cancellation policy?</h1></div>
          <div className=' md:w-3/5'><p className=' font-extralight text-gray-500 text-sm md:text-lg'>Cancellations made at least 24 hours before the tour date will receive a full refund. Cancellations made less than 24 hours before the tour date will incur a cancellation fee. Please refer to our cancellation policy page for detailed information.</p>
          </div>
        </div>
      </section>
      <MainFooter/>
    </main>
  )
}

export default ContactUs
