import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import { DatePicker, Button, Dropdown, Modal, message } from 'antd';
import { ExclamationCircleOutlined, EnvironmentOutlined } from '@ant-design/icons';
import MainFooter from '../Components/MainFooter';
import SecondaryHeader from '../Components/SecondaryHeader';

const { confirm } = Modal;
const { RangePicker } = DatePicker;

const MyAccount = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [userName, setUserName] = useState('John Doe');
  const [loggedIn, setLoggedIn] = useState(true);
  const [userMail, setUserMail] = useState('johndoe@gmail.com');
  const [dropdownVisible, setDropdownVisible] = useState(null);
  const [isCancellationModalOpen, setIsCancellationModalOpen] = useState(false);
  const [isRefundModalOpen, setIsRefundModalOpen] = useState(false);
  const dropdownRef = useRef(null);

  const [youmayalsolike, setyoumayalsolike] = useState([
    { id: 1, title: 'Private Aerial Tour of Curaçao', location: 'Willemstad', review: '4.0', price: '234.00', image: '../../assets/image//private-jet.png' },
    { id: 2, title: 'Curaçao Guided ', location: 'Spanish Water Bay', review: '4.8', price: '150.00', image: '../../assets/image//Screenshot 2024-01-27 at 22.28.17.png' }

  ]);
  const [myBookings, setmyBookings] = useState([
    {
      id: 1, title: 'Private Aerial Tour of Curaçao', location: 'Willemstad', price: '234.00', image: '../../assets/image//private-jet.png', name: 'John Doe', mail: 'jhondoe@gmail.com', phone: '112233445', tripStart: '22-12-2024', tripEnd: '25-12-2024', bookingDate: '22-12-2024', boookingTime: '22:00', tripTime: '22:00', paymentMethod: 'VISA card', paymentStatus: 'Completed', adultMembers: 3, children: 0, Language: 'English'
    },
    {
      id: 1, title: 'Private Aerial Tour of Curaçao', location: 'Willemstad', price: '234.00', image: '../../assets/image//private-jet.png', name: 'John Doe', mail: 'jhondoe@gmail.com', phone: '112233445', tripStart: '22-12-2024', tripEnd: '25-12-2024', bookingDate: '22-12-2024', boookingTime: '22:00', tripTime: '22:00', paymentMethod: 'VISA card', paymentStatus: 'Completed', adultMembers: 3, children: 0, Language: 'English'
    },
    {
      id: 1, title: 'Private Aerial Tour of Curaçao', location: 'Willemstad', price: '234.00', image: '../../assets/image//private-jet.png', name: 'John Doe', mail: 'jhondoe@gmail.com', phone: '112233445', tripStart: '22-12-2024', tripEnd: '25-12-2024', bookingDate: '22-12-2024', boookingTime: '22:00', tripTime: '22:00', paymentMethod: 'VISA card', paymentStatus: 'Completed', adultMembers: 3, children: 0, Language: 'English'
    },


  ]);

  const handleManageClick = (id) => {
    setDropdownVisible(dropdownVisible === id ? null : id);
  };

  const handleOptionClick = (option, activity) => {
    console.log(option, activity);
    setDropdownVisible(null);
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setDropdownVisible(null);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const showLogoutConfirm = () => {
    confirm({
      title: 'Are you sure you want to log out?',
      icon: <ExclamationCircleOutlined />,
      content: 'Logging out will end your current session.',
      okText: 'Yes, Log Out',
      okType: 'danger',
      cancelText: 'Cancel',
      onOk() {
        setLoggedIn(false);
        message.success('You have been logged out successfully.');
      },
      onCancel() {
        message.info('Logout cancelled.');
      },
    });
  };

  const items = [
    {
      key: '1',
      label: (
        <a onClick={() => setIsCancellationModalOpen(true)}>
          Cancellation Policy
        </a>
      ),
    },
    {
      key: '2',
      label: (
        <a onClick={() => setIsRefundModalOpen(true)}>
          Reschedule Policy
        </a>
      ),
    },
  ];

  const LikeButton = ({ id }) => {
    const [isFilled, setIsFilled] = useState(true);

    const handleClick = (e) => {
      e.preventDefault();
      setIsFilled(!isFilled);
    };

    return (
      <div
        className='bg-white w-8 h-8 rounded-full absolute top-1 right-1 flex justify-center items-center z-10'
        onClick={handleClick}
        style={{ cursor: 'pointer' }}
      >
        <svg
          width="18"
          height="15"
          viewBox="0 0 15 13"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M7.50012 12.2822L1.64353 6.97733C-1.53939 3.7944 3.13951 -2.31681 7.50012 2.62733C11.8607 -2.31681 16.5184 3.81562 13.3567 6.97733L7.50012 12.2822Z"
            stroke="#F0B059"
            strokeWidth="1.06098"
            strokeLinecap="round"
            strokeLinejoin="round"
            fill={isFilled ? "orange" : "none"}
          />
        </svg>
      </div>
    );
  };

  return (
    <main>
      <SecondaryHeader />

      <section className='p-4 md:p-8'>
        <div className='flex justify-between items-center'>
          <div className='flex justify-start gap-4 items-center'>
            <div className='w-20 h-20 bg-white rounded-full'></div>
            <div>
              <p className='font-bold text-xl md:text-3xl text-gray-700'>{loggedIn ? userName : 'Guest'}</p>
              <p className='font-normal text-gray-500'>{loggedIn ? userMail : ''}</p>
            </div>
          </div>
          {loggedIn ? (
            <a
              onClick={showLogoutConfirm}
              className='text-theorange-400 opacity-70 hover:opacity-100 transition-all cursor-pointer'
            >
              Log Out
            </a>
          ) : (
            <a
              href='/userlogin'
              className='text-theorange-400 opacity-70 hover:opacity-100 transition-all cursor-pointer'
            >
              Sign Up or Log In
            </a>
          )}
        </div>
      </section>

      <section className="p-5 md:p-8">
        <h1 className="text-2xl text-theblue-400 md:text-3xl font-bold">Favourites</h1>

        <div className="activities-list w-full flex gap-4 justify-start mt-5 pb-7 relative scroll-smooth flex-wrap">
          {youmayalsolike.map(activity => (
            <Link to={`/trip/${activity.id}`} key={activity.id} className="flex-1 min-w-[292px] lg:max-w-[292px] w-[280px]">
              <div className="bg-white border-2 border-white rounded-3xl p-2 hover:border-2 hover:border-theblue-400 transition-all relative shadow-lg image-container" style={{ minHeight: '380px' }}>
                <div className='relative group'>
                  <img className='w-full h-44 object-cover rounded-xl' src={activity.image} />
                  <LikeButton id={youmayalsolike.id} />
                </div>
                <div className='flex justify-between mt-2'>
                  <h2 className="text-md font-medium w-3/4 leading-tight m text-gray-700">{activity.title}</h2>
                  <div className='flex items-center gap-2'>
                    <p className='text-gray-500'>4.0</p>
                    <img src="../../assets/svg/star.svg" alt="" />
                  </div>
                </div>
                <p className="text-theorange-400 font-normal text-xs">{activity.location}</p>

                <div className='flex justify-between absolute bottom-5 w-11/12'>
                  <div className='flex flex-col justify-center gap-1'>
                    <div className='flex items-center gap-2 text-gray-500 font-normal text-xs text-left'>
                      <img src="../../assets/svg/3days.svg" alt="" />
                      3-days
                    </div>
                    <div className='flex items-center gap-2 text-gray-500 font-normal text-xs'>
                      <img src="../../assets/svg/bprice.svg" alt="" />
                      Best price
                    </div>
                    <div className='flex justify-center items-center gap-2 text-gray-500 font-normal text-xs'>
                      <img src="../../assets/svg/24hr.svg" alt="" />
                      free 24 hours cancellation
                    </div>
                  </div>
                  <div className='flex flex-col text-right absolute bottom-0 right-0 gap-3'>
                    <p className='text-xl font-semibold text-theblue-400 leading-none'>$ {activity.price}</p>
                  </div>
                </div>
              </div>
            </Link>
          ))}
        </div>
      </section>

      <section className="p-5 md:p-8">
        <h1 className="text-2xl text-theblue-400 md:text-3xl font-bold">My Bookings</h1>

        <div className="activities-list w-full gap-4 justify-start mt-5 relative scroll-smooth flex-wrap grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4">
        {myBookings.map(activity => (
            <div key={activity.id} className=" w-full">
              <div className="bg-white border-2 border-white rounded-2xl p-2 transition-all relative shadow-lg image-container">
                <div className='relative group'>
                  <img className='w-full h-44 object-cover rounded-xl' src={activity.image} alt="activity" />
                </div>
                <div className='flex justify-between mt-2'>
                  <h2 className="text-md font-medium leading-tight text-gray-700">{activity.title}</h2>
                </div>
                <div className="mt-4 relative">
                  <p className="text-sm text-gray-800 w-full">
                    <span className="font-medium w-1/2">Customer name: </span>{activity.name}
                  </p>
                  <p className="text-sm text-gray-800">
                    <span className="font-medium">Mail Id: </span>{activity.mail}
                  </p>
                  <p className="text-sm text-gray-800">
                    <span className="font-medium">Phone: </span>{activity.phone}
                  </p>
                  <p className="text-sm text-gray-800">
                    <span className="font-medium">Trip date: </span>{activity.tripStart} - {activity.tripEnd}
                  </p>
                  <p className="text-sm text-gray-800">
                    <span className="font-medium">Booking date & time: </span>{activity.bookingDate}, {activity.bookingTime}
                  </p>
                  <p className="text-sm text-gray-800">
                    <span className="font-medium">Trip time: </span>{activity.tripTime}
                  </p>
                  <p className="text-sm text-gray-800">
                    <span className="font-medium">Payment method: </span>{activity.paymentMethod}
                  </p>
                  <p className="text-sm text-gray-800">
                    <span className="font-medium">Payment status: </span>{activity.paymentStatus}
                  </p>
                  <p className="text-sm text-gray-800">
                    <span className="font-medium">Member: </span>{activity.adultMembers} adults & {activity.children} child(ren)
                  </p>
                  <p className="text-sm text-gray-800">
                    <span className="font-medium">Language: </span>English
                  </p>
                  <Dropdown
                    menu={{ items }}
                    trigger={['click']}
                    overlayClassName='custom-dropdown'
                    className='mt-4 w-full'
                  >
                    <Button className="text-theorange-400 ">Manage</Button>
                  </Dropdown>
                </div>
              </div>
            </div>
          ))}
        </div>
      </section>

      <MainFooter />

      <Modal
        title="Cancellation Policy"
        visible={isCancellationModalOpen}
        onCancel={() => setIsCancellationModalOpen(false)}
        footer={null}
      >
        <p>We recognize that cancellations are sometimes necessary, and we aim to offer a fair policy. If you need to cancel your booking, cancellations made more than 7 days before the scheduled activity will receive a full refund, minus a $30 processing fee per person. For cancellations made between 3 to 7 days prior to the activity, we will provide a 50% refund of the total booking amount. Unfortunately, cancellations made less than 3 days before the activity, as well as no-shows, are not eligible for a refund. For group bookings of 10 or more, cancellations must be made at least 14 days in advance to receive a refund. In cases where The Trip Curacao cancels due to adverse weather conditions, you will have the option to reschedule the activity or receive a full refund. Special circumstances, such as personal emergencies or travel disruptions, may be considered for exceptions on a case-by-case basis, and documentation may be required.</p>
        <div className=' w-full flex justify-end gap-2'>
          <Button type='primary'>Cancel trip</Button>
          <Button>Ask for refund</Button>
        </div>
      </Modal>

      <Modal
        title="Reschedule Policy"
        visible={isRefundModalOpen}
        onCancel={() => setIsRefundModalOpen(false)}
        footer={null}
      >
        <p>At The Trip Curacao, we understand that sometimes plans need to be adjusted. If you need to reschedule your booking, please contact us at least 48 hours before your scheduled activity. A rescheduling fee of $20 per person may apply, and all rescheduling requests are subject to availability. If the new date or time chosen is more expensive than your original booking, you will need to pay the difference at the time of rescheduling. If the rescheduled activity costs less, we may issue a credit or refund for the difference. Please note that we are unable to accommodate rescheduling requests made less than 48 hours before the activity, and any no-shows will result in the forfeiture of your booking without the option to reschedule.</p>
        <div className=' w-full flex justify-end gap-2'>
          <Button type='primary'>Reschedule</Button>
          
        </div>
      </Modal>

    </main>
  );
};

export default MyAccount;
