import React from 'react'
import { useState } from 'react';
import ImageCard from '../Components/ImageCard';
import MainFooter from '../Components/MainFooter';
import BlueHeader from '../Components/BlueHeader';

function PrivacyPolicy() {

  return (
    <main>
      <BlueHeader />

      <section className=" overflow-hidden -z-0 h-screen flex justify-center md:justify-between items-center" >
        <div className="absolute inset-0 bg-gradient-to-r bg-black" ></div>
        <img src='../../assets/privacy.jpg' alt="Hero" className=" opacity-50 md:opacity-100 md:w-1/3 object-cover absolute z-5  md:right-28 rounded-full" />
        <div className=" w-full md:w-2/3 absolute inset-0 flex items-left flex-col justify-center p-8 gap-3">
          {/* <p className='text-center md:text-left text-white italic font-light'>Local Experience</p> */}
          <h1 class="text-4xl text-center text-white md:text-6xl md:text-left font-extrabold ">Sip Cautiously</h1>
          <p className='text-sm text-center md:text-left md:text-base text-white font-light md:w-9/12'>
            TheTripCuraçao is committed to protecting your privacy. This Privacy Policy outlines
            how we collect, use, and safeguard your personal information. Personally information
            is information that, on its own or together with other information, can be used to
            identify, contact, or locate a single person, or to identify an individual in context.
            Please read our privacy policy carefully to gain a clear understanding of how we
            collect, use, protect, or otherwise handle your personally identifiable information in
            accordance with our website.
          </p>
        </div>
      </section>
      <section className='p-5 md:p-8 '>
        <div className='flex flex-col md:flex-row gap-3'>
          <div className=' md:w-2/5'><h1 className=' text-2xl font-bold text-theblue-400'>Information Collection:</h1></div>
          <div className=' md:w-3/5'><p className=' font-extralight text-gray-500 text-sm md:text-lg'>We handle your personal data because you use our services
            and/or have provided this information to us. Below is an overview of the personal
            data we process: First and last name, Gender, Accommodation, Country, Language,
            Telephone number, Email address, Bank account number, IP address, Location data,
            Information about your activities on our website, Internet browser, and device type.
            Users can update their personal information by emailing us at
            info@thetripcuracao.com.</p>
          </div>
        </div>
        <div className=' w-full bg-theorange-400 h-[1px] mb-8 mt-8'></div>
        <div className='flex flex-col md:flex-row gap-3'>
          <div id='#cancellation' className=' md:w-2/5'><h1 className=' text-2xl font-bold text-theblue-400'>Use of Information:</h1></div>
          <div className=' md:w-3/5'><p className=' font-extralight text-gray-500 text-sm md:text-lg'>Your information is used to process bookings, provide customer
            service, send newsletters, and improve our services. We do not sell or share your
            personal information with third parties, except as necessary to provide our services or
            comply with legal obligations.</p>
          </div>
        </div>
        <div className=' w-full bg-theorange-400 h-[1px] mb-8 mt-8'></div>
        <div className='flex flex-col md:flex-row gap-3'>
          <div className=' md:w-2/5'><h1 className=' text-2xl font-bold text-theblue-400'>Automated Decision-Making:</h1></div>
          <div className=' md:w-3/5'><p className=' font-extralight text-gray-500 text-sm md:text-lg'>YTheTripCuraçao does not make decisions with
            significant consequences for individuals based solely on automated
            processing. This means that decisions are not made by computer programs or
            systems without human intervention (for example, by an employee of
            TheTripCuraçao).</p>
          </div>
        </div>
        <div className=' w-full bg-theorange-400 h-[1px] mb-8 mt-8'></div>
        <div className='flex flex-col md:flex-row gap-3'>
          <div className=' md:w-2/5'><h1 className=' text-2xl font-bold text-theblue-400'>Special and Sensitive Data:</h1></div>
          <div className=' md:w-3/5'><p className=' font-extralight text-gray-500 text-sm md:text-lg'>Our services are not intended to collect data from
            individuals under 16 years old without parental consent. If we become aware that we
            have inadvertently collected such data, we will delete it promptly. If you believe we
            have collected data about a minor without consent, please contact us at
            info@thetripcuracao.com.</p>
          </div>
        </div>
        <div className=' w-full bg-theorange-400 h-[1px] mb-8 mt-8'></div>
        <div className='flex flex-col md:flex-row gap-3'>
          <div className=' md:w-2/5'><h1 className=' text-2xl font-bold text-theblue-400'>Data Security:</h1></div>
          <div className=' md:w-3/5'><p className=' font-extralight text-gray-500 text-sm md:text-lg'>TheTripCuraçao implement security measures to protect your
            personal information from unauthorized access, alteration, disclosure, or destruction.
            If you believe your data is not properly secured or if there are indications of misuse,
            please contact us at info@thetripcuracao.com.</p>
          </div>
        </div>
        <div className=' w-full bg-theorange-400 h-[1px] mb-8 mt-8'></div>
        <div className='flex flex-col md:flex-row gap-3'>
          <div className=' md:w-2/5'><h1 className=' text-2xl font-bold text-theblue-400'>Data Storage:</h1></div>
          <div className=' md:w-3/5'><p className=' font-extralight text-gray-500 text-sm md:text-lg'>We store your data securely for as long as it is necessary to provide our
            services to you or as required by law. Data retention periods vary depending on the
            type of information and the purpose for which it is used.</p>
          </div>
        </div>
        <div className=' w-full bg-theorange-400 h-[1px] mb-8 mt-8'></div>
        <div className='flex flex-col md:flex-row gap-3'>
          <div className=' md:w-2/5'><h1 className=' text-2xl font-bold text-theblue-400'>Cookies:</h1></div>
          <div className=' md:w-3/5'><p className=' font-extralight text-gray-500 text-sm md:text-lg'>Our website uses cookies to enhance your browsing experience. Please refer
            to our Cookies Policy for more details.</p>
          </div>
        </div>
        <div className=' w-full bg-theorange-400 h-[1px] mb-8 mt-8'></div>
        <div className='flex flex-col md:flex-row gap-3'>
          <div className=' md:w-2/5'><h1 className=' text-2xl font-bold text-theblue-400'>Your Rights:</h1></div>
          <div className=' md:w-3/5'><p className=' font-extralight text-gray-500 text-sm md:text-lg'>You have the right to view, update, or delete your personal information. Contact us at info@thetripcuracao.com to exercise these right <br />
            You also have the right to withdraw your consent for data processing or object
            to the processing of your personal data by TheTripCuraçao. <br className=' mt-3' />
            To ensure the request is made by you, please send a copy of your ID with your
            request. Make sure to black out your photo, MRZ (machine-readable zone, the strip
            with numbers at the bottom of the passport), passport number, and Citizen Service
            Number (BSN) in this copy to protect your privacy. We will respond to your request
            as soon as possible.
          </p>
          </div>
        </div>
        <div className=' w-full bg-theorange-400 h-[1px] mb-8 mt-8'></div>
        <div className='flex flex-col md:flex-row gap-3'>
          <div className=' md:w-2/5'><h1 className=' text-2xl font-bold text-theblue-400'>Contact Information for Privacy Concerns:</h1></div>
          <div className=' md:w-3/5'><p className=' font-extralight text-gray-500 text-sm md:text-lg'><span className='font-medium '>If you have any questions about this privacy policy, please contact us at:</span> <br /> TheTripcuracao part of Magnet group <br />
            Email: info@thetripcuracao.com<br />
            Thetrip whatsapp: +31687205513<br />
            Curacao contact: + (599)6968704</p>
          </div>
        </div>
        
      </section>



      <MainFooter />

    </main>
  )
}

export default PrivacyPolicy
