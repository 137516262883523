import React from 'react';
import { Form, Input, Button, Card, message } from 'antd';
import { useHistory } from 'react-router-dom';

const ForgotPassword = () => {
//   const history = useHistory();

  const onFinish = (values) => {
    console.log('Success:', values);
    // Here you would typically send a request to your backend to handle the password reset
    message.success('A reset link has been sent to your email.');
    // Redirect to login page after submitting the form
    // history.push('/userlogin');
  };

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  return (
    <div className="flex flex-col justify-center items-center h-screen">
        <a className='logo text-5xl text-theblue-400'>TheTrip <br />Curaçao</a>
      <Card title="Forgot Password" className="w-full max-w-md">
        <p className="text-center mb-4">
          Enter your email address below, and we’ll send you a link to reset your password.
        </p>
        <Form
          name="forgot_password"
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          layout="vertical"
        >
          <Form.Item
            label="Email"
            name="email"
            rules={[
              { required: true, message: 'Please enter your email!' },
              { type: 'email', message: 'Please enter a valid email address!' }
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item>
            <Button type="primary" htmlType="submit" block>
              Send Reset Link
            </Button>
          </Form.Item>
        </Form>

        <p className="text-center">
          Remember your password?{' '}
          <a href="/userlogin" className="text-theorange-400 cursor-pointer">
            Log In
          </a>
        </p>
      </Card>
    </div>
  );
};

export default ForgotPassword;
