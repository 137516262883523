import React, { useState, useEffect } from 'react';
import { Modal, Switch, Input, Button, Upload, Select, Space, message } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { MinusCircleOutlined } from '@ant-design/icons';

const options = [
  { label: 'Top rated', value: '../../assets/svg/allbagdes/top.svg' },
  { label: 'Photographers', value: '../../assets/svg/allbagdes/photogrpaher.svg' },
  { label: 'Eco friendly', value: '../../assets/svg/allbagdes/eco.svg' },
  { label: 'Group', value: '../../assets/svg/allbagdes/group.svg' },
  { label: 'Sells fast', value: '../../assets/svg/allbagdes/sells.svg' },
  { label: 'Cultural', value: '../../assets/svg/allbagdes/cultural.svg' },
  { label: 'Local', value: '../../assets/svg/allbagdes/local.svg' },
];

const AddTripModal = ({ visible, onClose, onSave }) => {
  const initialTripData = {
    id: null,
    title: '',
    API: '',
    APIkey: '',
    APIurl: '',
    fetchAAPI: '',
    price: '',
    age: { min: '', max: '' },
    location: '',
    category: '',
    company: '',
    duration: '',
    badge: [],
    pickup: '',
    pickupPrice: '',
    pickupDirection: '',
    about: '',
    dothis: ['', '', '', '', ''],
    insights: '',
    knowbefore: ['', '', '', ''],
    presentitems: { requiredequipment: false, wc: false, chairs: false },
    suitablefor: { single: false, couple: false, children: false, Group: 'false' },
    includeditems: { photos: false, videos: false, water: false },
    availablelanguages: { english: false, dutch: false, spanish: false, papiamento: false },
    whattobring: ['', '', '', ''],
    immediateBooking: false,
    images: [],
    relatedTrips: []
  };
  const [api, setApi] = useState(false)
  const [tripData, setTripData] = useState(initialTripData);

  const handleInputChange = (field, value) => {
    setTripData(prevState => ({ ...prevState, [field]: value }));
  };

  const handleBadgeChange = (value) => {
    setTripData(prevState => ({ ...prevState, badge: value }));
  };

  const handleNestedInputChange = (parentField, field, value) => {
    setTripData(prevState => ({
      ...prevState,
      [parentField]: { ...prevState[parentField], [field]: value }
    }));
  };

  const handleArrayInputChange = (field, index, value) => {
    const updatedArray = [...tripData[field]];
    updatedArray[index] = value;
    setTripData(prevState => ({ ...prevState, [field]: updatedArray }));
  };

  const validateFields = () => {
    const requiredFields = ['title', 'price', 'location', 'category', 'company', 'duration'];
    for (let field of requiredFields) {
      if (!tripData[field]) {
        message.error(`${field.charAt(0).toUpperCase() + field.slice(1)} is empty`);
        return false;
      }
    }
    return true;
  };

  const handleSave = () => {
    if (validateFields()) {
      onSave({ ...tripData, id: Date.now() });  // Assign a unique ID
      setTripData(initialTripData);  // Reset form
      onClose();
    }
  };


  const [knowBeforeInputs, setKnowBeforeInputs] = useState(['']);
  const [doThisInputs, setDoThisInputs] = useState(['']);
  const maxInputs = 4;

  // const handleInputChange = (index, value) => {
  //   const newInputs = [...inputs];
  //   newInputs[index] = value;
  //   setInputs(newInputs);
  // };
  const addKnowBeforeInput = () => {
    if (knowBeforeInputs.length < maxInputs) {
      setKnowBeforeInputs([...knowBeforeInputs, '']);
    }
  };

  const addDoThisInput = () => {
    if (doThisInputs.length < maxInputs) {
      setDoThisInputs([...doThisInputs, '']);
    }
  };

  const removeKnowBeforeInput = (index) => {
    const newInputs = knowBeforeInputs.filter((_, i) => i !== index);
    setKnowBeforeInputs(newInputs);
  };

  const removeDoThisInput = (index) => {
    const newInputs = doThisInputs.filter((_, i) => i !== index);
    setDoThisInputs(newInputs);
  };

  const [pickup, setPickup] = useState(false);
  const [pickupPrice, setPickupPrice] = useState('');
  const [pickupDirection, setPickupDirection] = useState('');

  const handlePickupChange = (checked) => {
    setPickup(checked);
  };
  const handleApiChange = (checked) => {
    setApi(checked);
  };

  useEffect(() => {
    if (visible) {
      setTripData(initialTripData);
    }
  }, [visible]);

  const relatedtripsoptions = [
    { label: 'Snorkeling', value: 'snorkeling' },
    { label: 'Hiking', value: 'hiking' },
    { label: 'Kayaking', value: 'kayaking' },
    { label: 'Scuba Diving', value: 'scuba_diving' },
    { label: 'Sunset Cruise', value: 'sunset_cruise' },
  ];

  const handlerelatedTripChange = (value) => {
    setTripData({
      ...tripData,
      relatedTrips: value,
    });
    console.log(`selected ${value}`);
  };

  return (
    <Modal title="Add Trip" visible={visible} onCancel={onClose} footer={null} width={1200}>
      {tripData && (
        <div className="grid grid-cols-2 gap-8">
          <div className='flex flex-col gap-2'>
            <div>
              {/* Thumbnail Image Upload */}
              <div>
                <h4>Thumbnail Image</h4>
                <Upload
                  listType="picture-card"
                  fileList={tripData.thumbnail ? [tripData.thumbnail] : []}
                  onChange={({ fileList }) => handleInputChange('thumbnail', fileList[0])}
                  beforeUpload={() => false}
                  maxCount={1} // Ensures only one thumbnail image can be uploaded
                >
                  {!tripData.thumbnail && (
                    <div>
                      <PlusOutlined />
                      <div style={{ marginTop: 8 }}>Upload Thumbnail</div>
                    </div>
                  )}
                </Upload>
              </div>

              {/* Other Images Upload */}
              <div style={{ marginTop: 16 }}>
                <h4>Additional Images or videos</h4>
                <Upload
                  listType="picture-card"
                  fileList={tripData.media}
                  onChange={({ fileList }) => handleInputChange('media', fileList)}
                  beforeUpload={() => false}
                  multiple
                  maxCount={3} // Ensures only up to 3 media files (images/videos) can be uploaded
                  accept="image/*,video/*" // Allow both images and videos
                >
                  {tripData.images.length < 3 && (
                    <div>
                      <PlusOutlined />
                      <div style={{ marginTop: 8 }}>Upload</div>
                    </div>
                  )}
                </Upload>

              </div>
            </div>
            <div className=' flex flex-col gap-2'>
              <Input placeholder="Trip name" value={tripData.title} onChange={(e) => handleInputChange('title', e.target.value)} />
              <Input placeholder="Price" value={tripData.price} onChange={(e) => handleInputChange('price', e.target.value)} />
              <div className=' flex gap-3'>
                <Input
                  placeholder="Min Age"
                  value={tripData.age.min}
                  onChange={(e) => handleNestedInputChange('age', 'min', e.target.value)}
                />
                <Input
                  placeholder="Max Age"
                  value={tripData.age.max}
                  onChange={(e) => handleNestedInputChange('age', 'max', e.target.value)}
                />
              </div>
              <Input placeholder="Location" value={tripData.location} onChange={(e) => handleInputChange('location', e.target.value)} />



              <Input placeholder="Category" value={tripData.category} onChange={(e) => handleInputChange('category', e.target.value)} />

              <Input placeholder="Company" value={tripData.company} onChange={(e) => handleInputChange('company', e.target.value)} />

              <Input placeholder="Duration" value={tripData.duration} onChange={(e) => handleInputChange('duration', e.target.value)} />

              <Select
                mode="tags"
                placeholder="Please select"
                value={tripData.badge}
                onChange={handleBadgeChange}
                style={{ width: '100%' }}
                options={options}
              />

              <Input.TextArea placeholder="About" value={tripData.about} onChange={(e) => handleInputChange('about', e.target.value)} />
              <div>
                <h3>Do This</h3>
                {doThisInputs.map((input, index) => (
                  <Input
                    className=' mt-2'
                    key={index}
                    placeholder={`Do this #${index + 1}`}
                    value={input}
                    onChange={(e) => handleInputChange(index, e.target.value)}
                    suffix={
                      index > 0 && (
                        <MinusCircleOutlined
                          style={{ color: 'red', cursor: 'pointer' }}
                          onClick={() => removeDoThisInput(index)}
                        />
                      )
                    }
                  />
                ))}

                {doThisInputs.length < maxInputs && (
                  <Button type="dashed" onClick={addDoThisInput} block className=' mt-2'>
                    Add Input
                  </Button>
                )}
              </div>
              <Input.TextArea placeholder="Insights" value={tripData.insights} onChange={(e) => handleInputChange('insights', e.target.value)} />
            </div>
          </div>


          <div className=' flex flex-col gap-2'>
            <div>
              <h3>Know Before You Go</h3>
              {knowBeforeInputs.map((input, index) => (
                <Input
                  className=' mt-2'
                  key={index}
                  placeholder={`Know before you go #${index + 1}`}
                  value={input}
                  onChange={(e) => handleInputChange(index, e.target.value)}
                  suffix={
                    index > 0 && (
                      <MinusCircleOutlined
                        style={{ color: 'red', cursor: 'pointer' }}
                        onClick={() => removeKnowBeforeInput(index)}
                      />
                    )
                  }
                />
              ))}

              {knowBeforeInputs.length < maxInputs && (
                <Button type="dashed" onClick={addKnowBeforeInput} block className=' mt-2'>
                  Add Input
                </Button>
              )}
            </div>


            <div className="col-span-2 grid grid-cols-4 gap-4">
              <div>
                <label>Present</label>
                {['requiredequipment', 'wc', 'chairs'].map((field) => (
                  <div key={field}>
                    <Switch size='small' checked={tripData.presentitems[field]} onChange={(checked) => handleNestedInputChange('presentitems', field, checked)} /> {field}
                  </div>
                ))}
              </div>
              <div>
                <label>Suitable for</label>
                {['single', 'couple', 'children', 'group'].map((field) => (
                  <div key={field}>
                    <Switch size='small' checked={tripData.suitablefor[field]} onChange={(checked) => handleNestedInputChange('suitablefor', field, checked)} /> {field}
                  </div>
                ))}
              </div>
              <div>
                <label>Include</label>
                {['photos', 'videos', 'water'].map((field) => (
                  <div key={field}>
                    <Switch size='small' checked={tripData.includeditems[field]} onChange={(checked) => handleNestedInputChange('includeditems', field, checked)} /> {field}
                  </div>
                ))}
              </div>
              <div>
                <label>Languages</label>
                {['english', 'dutch', 'spanish', 'papiamento'].map((field) => (
                  <div key={field}>
                    <Switch size='small' checked={tripData.availablelanguages[field]} onChange={(checked) => handleNestedInputChange('availablelanguages', field, checked)} /> {field}
                  </div>
                ))}
              </div>
            </div>

            <div className="col-span-2 flex flex-col gap-2">
              <label>What to bring</label>
              {['#1', '#2', '#3', '#4'].map((label, index) => (
                <Input
                  key={index}
                  placeholder={`Item ${label}`}
                  value={tripData.whattobring[index]}
                  onChange={(e) => handleArrayInputChange('whattobring', index, e.target.value)}
                />
              ))}
            </div>
            <div>
              <Select
                mode="multiple"
                allowClear
                style={{
                  width: '100%',
                }}
                placeholder="Please select related trip"
                onChange={handlerelatedTripChange}
                options={relatedtripsoptions}
              />
            </div>
            <div className="col-span-2">
              <div className="flex items-center gap-2">
                <Switch
                  checked={pickup}
                  onChange={handlePickupChange}
                />
                <span>Pickup</span>
              </div>
              {pickup && (
                <div className="mt-4">
                  <Input
                    placeholder="Pickup Price"
                    value={pickupPrice}
                    onChange={(e) => setPickupPrice(e.target.value)}
                    type="number"
                    min={0}
                    className="mb-2"
                  />
                  <Input
                    placeholder="Pickup Direction"
                    value={pickupDirection}
                    onChange={(e) => setPickupDirection(e.target.value)}
                  />
                </div>
              )}
            </div>
            <div className="col-span-2">
              <div className="flex items-center gap-2">
                <Switch
                  checked={api}
                  onChange={handleApiChange}
                />
                <span>API</span>
              </div>
              {api && (
                <div className="mt-4">
                  <Input
                    placeholder="API URL"
                    value={pickupPrice}
                    onChange={(e) => setPickupPrice(e.target.value)}

                    min={0}
                    className="mb-2"
                  />
                  <Input
                    placeholder="API key"
                    value={pickupDirection}
                    onChange={(e) => setPickupDirection(e.target.value)}
                  />
                  <Input
                    placeholder="API secret"
                    value={pickupDirection}
                    onChange={(e) => setPickupDirection(e.target.value)}
                  />
                  <div className="flex items-center gap-2 mt-2">
                    <Switch
                    // checked={api}
                    // onChange={handleApiChange}
                    />
                    <span>Fetch price form API</span>
                  </div>

                </div>

              )}
            </div>

            <div className="col-span-2">
              <Switch checked={tripData.immediateBooking} onChange={(checked) => handleInputChange('immediateBooking', checked)} /> Immediate booking
            </div>


          </div>

          <div className="col-span-2 flex justify-end gap-2">
            <Button type="primary" onClick={handleSave}>Save trip</Button>
          </div>
        </div>
      )}
    </Modal>
  );
};

export default AddTripModal;
