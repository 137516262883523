import React, { useState } from 'react';
import { Form, Input, Button, Checkbox, notification } from 'antd';
import { LockOutlined, UserOutlined } from '@ant-design/icons';
// import 'antd/dist/antd.css';

const AdminLogin = () => {
  const [loading, setLoading] = useState(false);

  const onFinish = (values) => {
    setLoading(true);
    console.log('Received values of form: ', values);

    // Simulate a login request
    setTimeout(() => {
      setLoading(false);
      notification.success({
        message: 'Login Successful',
        description: 'You have successfully logged in.',
      });
      // Redirect to the admin dashboard or any other page
      window.location.href = '/admin/dashboard';
    }, 1500);
  };

  return (
    <main className=' bg-gray-100 items-center'>
      
      <div className="flex flex-col justify-center items-center min-h-screen gap-10">
      <a href='/' className='logo text-5xl text-theblue-400'>TheTrip <br />Curaçao</a>
        <Form
          name="admin_login"
          className="w-full max-w-sm bg-white p-8 rounded-lg shadow-md"
          initialValues={{ remember: true }}
          onFinish={onFinish}
        >
          <Form.Item
            name="username"
            rules={[{ required: true, message: 'Please input your Username!' }]}
          >
            <Input
              prefix={<UserOutlined className="site-form-item-icon" />}
              placeholder="Username"
              className="w-full py-2 px-3 border rounded"
            />
          </Form.Item>
          <Form.Item
            name="password"
            rules={[{ required: true, message: 'Please input your Password!' }]}
          >
            <Input
              prefix={<LockOutlined className="site-form-item-icon" />}
              type="password"
              placeholder="Password"
              className="w-full py-2 px-3 border rounded"
            />
          </Form.Item>
          <Form.Item>
            <Form.Item name="remember" valuePropName="checked" noStyle>
              <Checkbox>Remember me</Checkbox>
            </Form.Item>
          </Form.Item>

          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              className="w-full py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
              loading={loading}
            >
              Log in
            </Button>
          </Form.Item>
        </Form>
      </div>
    </main>
  );
};

export default AdminLogin;
