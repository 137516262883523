import React, { useState, useEffect } from 'react';
import { Modal, Input, Button, Switch, Rate, Select, Upload } from 'antd';
import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';

const options = [
  { label: 'Top rated', value: '../../assets/svg/allbagdes/top.svg' },
  { label: 'Photographers', value: '../../assets/svg/allbagdes/photogrpaher.svg' },
  { label: 'Eco friendly', value: '../../assets/svg/allbagdes/eco.svg' },
  { label: 'Group', value: '../../assets/svg/allbagdes/group.svg' },
  { label: 'Sells fast', value: '../../assets/svg/allbagdes/sells.svg' },
  { label: 'Cultural', value: '../../assets/svg/allbagdes/cultural.svg' },
  { label: 'Local', value: '../../assets/svg/allbagdes/local.svg' },
];

const relatedTripsOptions = [
  { label: 'Snorkeling', value: 'snorkeling' },
  { label: 'Hiking', value: 'hiking' },
  { label: 'Kayaking', value: 'kayaking' },
  { label: 'Scuba Diving', value: 'scuba_diving' },
  { label: 'Sunset Cruise', value: 'sunset_cruise' },
];

const BookingModal = ({ visible, onClose, booking, onSave }) => {
  const [isEditMode, setIsEditMode] = useState(false);
  const [editableBooking, setEditableBooking] = useState(booking);


  const handleEditClick = () => {
    setIsEditMode(!isEditMode);
  };

  const handleDelete = () => {
    // Show confirmation before deletion
    Modal.confirm({
      title: 'Are you sure you want to delete this trip?',
      content: 'This action cannot be undone.',
      okText: 'Yes, Delete',
      okType: 'danger',
      cancelText: 'No, Cancel',
      onOk() {
        // Perform the delete operation
        deleteTrip(editableBooking.id); // Assuming each trip has a unique id
      },
    });
  };

  const deleteTrip = (tripId) => {
    // Update the trips array by filtering out the deleted trip
    setEditableBooking((prevBooking) => prevBooking.filter((trip) => trip.id !== tripId));
    // Clear the editable booking since it's deleted
    setEditableBooking(null);
    // Exit edit mode
    setIsEditMode(false);
  };
  // useEffect(() => {
  //   // Ensure age is always an object
  //   if (typeof booking.age === 'string') {
  //     const [min, max] = booking.age.split('-');
  //     setEditableBooking((prevBooking) => ({
  //       ...prevBooking,
  //       age: { min, max },
  //     }));
  //   }
  // }, [booking]);


  const handleChange = (field, value) => {
    setEditableBooking((prevBooking) => ({
      ...prevBooking,
      [field]: value,
    }));
  };

  const handleItemChange = (key, index, value) => {
    const updatedItems = [...editableBooking[key]];
    updatedItems[index] = value;
    setEditableBooking(prev => ({
      ...prev,
      [key]: updatedItems
    }));
  };

  const handleAddItem = (key) => {
    setEditableBooking(prev => ({
      ...prev,
      [key]: [...prev[key], '']
    }));
  };

  const handleDeleteItem = (key, index) => {
    const updatedItems = editableBooking[key].filter((_, i) => i !== index);
    setEditableBooking(prev => ({
      ...prev,
      [key]: updatedItems
    }));
  };


  const handleSave = () => {
    onSave(editableBooking); // Pass updated booking data to parent save function
    setIsEditMode(false); // Exit edit mode
  };


  const checksvg = (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24">
      <path fill="blue" fillRule="evenodd" d="M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75s-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12Zm13.36-1.814a.75.75 0 1 0-1.22-.872l-3.236 4.53L9.53 12.22a.75.75 0 0 0-1.06 1.06l2.25 2.25a.75.75 0 0 0 1.14-.094l3.75-5.25Z" clipRule="evenodd" />
    </svg>
  );

  const notchecksvg = (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 16 16">
      <path fill="orange" fillRule="evenodd" d="M8 15A7 7 0 1 0 8 1a7 7 0 0 0 0 14m2.78-4.22a.75.75 0 0 1-1.06 0L8 9.06l-1.72 1.72a.75.75 0 1 1-1.06-1.06L6.94 8L5.22 6.28a.75.75 0 0 1 1.06-1.06L8 6.94l1.72-1.72a.75.75 0 1 1 1.06 1.06L9.06 8l1.72 1.72a.75.75 0 0 1 0 1.06" clipRule="evenodd" />
    </svg>
  );
  const [previewVisible, setPreviewVisible] = useState(false);
  const [previewImage, setPreviewImage] = useState('');
  const [previewTitle, setPreviewTitle] = useState('');
  const [imageList, setImageList] = useState(editableBooking.images || []);
  const [thumbImg, setThumbImg] = useState(editableBooking.thumImg || []);

  const handlePreview = (file) => {
    setPreviewImage(file.url || file.thumbUrl);
    setPreviewTitle(file.name || file.url.substring(file.url.lastIndexOf('/') + 1));
    setPreviewVisible(true);
  };

  const handleCancel = () => setPreviewVisible(false);

  const handleThumbnail = (index) => {
  // Get the selected image to set as the thumbnail
  const newThumbnail = imageList[index];

  // Update the state for the thumbnail image
  setThumbImg(newThumbnail);

  // If you want to keep this in sync with the parent or another state
  handleChange('thumbnail', newThumbnail);
};

  const handleDeleteImage = (index) => {
    const updatedImages = [...imageList];
    updatedImages.splice(index, 1);
    setImageList(updatedImages);
    handleChange('images', updatedImages);
  };

  const handleImageUpload = (file) => {
    const newImage = URL.createObjectURL(file);
    const updatedImages = [...imageList, newImage];
    setImageList(updatedImages);
    handleChange('images', updatedImages);
    return false; // Prevent default upload behavior
  };

  
  return (
    <Modal visible={visible} onCancel={onClose} footer={null} width={1000}>
      {booking && (
        <div className="p-4">
          <h2 className="text-2xl font-bold mb-4">#{editableBooking.id}-{editableBooking.title}</h2>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            <div className="col-span-2 grid grid-cols-2 md:grid-cols-4 gap-2">
              <div className=' relative'>
                <Button className=' absolute cursor-default top-1 left-1 '>Thumbnail</Button>
                <img
                  src={editableBooking.thumImg}
                  // alt={`Image ${index + 1}`}
                  className="rounded-md w-full h-40 object-cover"
                />
                {isEditMode && (
                  <Button
                    type="link"
                    icon={<DeleteOutlined />}
                    onClick={handleThumbnail}
                    className="absolute top-0 right-0"
                  />
                )}
              </div>
              {editableBooking.images.map((image, index) => (
                <div key={index} className="relative">
                  <img
                    src={image}
                    alt={`Image ${index + 1}`}
                    className="rounded-md w-full h-40 object-cover"
                  />
                  {isEditMode && (
                    <Button
                      type="link"
                      icon={<DeleteOutlined />}
                      onClick={() => handleDeleteImage(index)}
                      className="absolute top-0 right-0"
                    />
                  )}
                </div>
              ))}

              {/* Separate Upload Button */}
              {isEditMode && editableBooking.images.length < 3 && (
                <div className="flex items-center justify-center h-40 border rounded-md">
                  <Upload
                    listType="picture-card"
                    showUploadList={false}
                    beforeUpload={handleImageUpload}
                    onPreview={handlePreview}
                  >
                    <div>
                      <PlusOutlined />
                      <div style={{ marginTop: 8 }}>Upload</div>
                    </div>
                  </Upload>
                </div>
              )}
            </div>
            {/*  */}
            <div>

              <div className="mb-2 flex">
                <span className="font-semibold">Immediate booking:</span>
                {isEditMode ? (
                  <Switch
                    checked={editableBooking.immediateBooking}
                    onChange={(checked) => handleChange('immediateBooking', checked)}
                  />
                ) : (
                  <span className="text-blue-500">
                    {editableBooking.immediateBooking ? checksvg : notchecksvg}
                  </span>
                )}
              </div>
              {/* <div className="mb-2">
                <span className="font-semibold">API key:</span>
                {isEditMode ? (
                  <Input value={editableBooking.API} onChange={(e) => handleChange('API', e.target.value)} />
                ) : (
                  editableBooking.API
                )}

              </div> */}
              <div className="mb-2">
                <span className="font-semibold">Age:</span>
                {isEditMode ? (
                  <div>
                    <Input
                      placeholder="Min Age"
                      value={editableBooking.age?.min}
                      onChange={(e) => handleChange('age', 'min', e.target.value)}
                      style={{ width: '48%', marginRight: '4%' }}
                    />
                    <Input
                      placeholder="Max Age"
                      value={editableBooking.age?.max}
                      onChange={(e) => handleChange('age', 'max', e.target.value)}
                      style={{ width: '48%' }}
                    />
                  </div>
                ) : (
                  `${editableBooking.age?.min} - ${editableBooking.age?.max}`
                )}
              </div>
              <div className="mb-2">
                <span className="font-semibold">Category:</span>
                {isEditMode ? (
                  <Input value={editableBooking.category} onChange={(e) => handleChange('category', e.target.value)} />
                ) : (
                  `${editableBooking.category}`
                )}
              </div>
              <div className="mb-2 flex">
                <span className="font-semibold">Pickup:</span>
                {isEditMode ? (
                  <Switch
                    checked={editableBooking.pickup}
                    onChange={(checked) => handleChange('pickup', checked)}
                  />
                ) : (
                  <span className="text-blue-500">
                    {editableBooking.pickup ? checksvg : notchecksvg}
                  </span>
                )}
              </div>
              {editableBooking.pickup ? <div className="mb-2">
                <span className="font-semibold">Pickup price:</span>
                {isEditMode ? (
                  <Input value={editableBooking.pickupprice} onChange={(e) => handleChange('pickupprice', e.target.value)} />
                ) : (
                  `$${editableBooking.pickupprice}`
                )}

              </div> : ''
              }
              {editableBooking.pickup ?
                <div className="mb-2">
                  <span className="font-semibold">Pickup direction:</span>
                  {isEditMode ? (
                    <Input value={editableBooking.pickupdirection} onChange={(e) => handleChange('pickupdirection', e.target.value)} />
                  ) : (
                    editableBooking.pickupdirection
                  )}

                </div> : ''
              }

              <div className="mb-2 flex">
                <span className="font-semibold">API:</span>
                {isEditMode ? (
                  <Switch
                    checked={editableBooking.API}
                    onChange={(checked) => handleChange('API', checked)}
                  />
                ) : (
                  <span className="text-blue-500">
                    {editableBooking.API ? checksvg : notchecksvg}
                  </span>
                )}
              </div>
              { }
              <div className="mb-2 flex">
                {editableBooking.API && (
                  <>
                    <span className="font-semibold">Fetch price from API:</span>
                    {isEditMode ? (
                      <Switch
                        checked={editableBooking.fetchAAPI}
                        onChange={(checked) => handleChange('fetchAAPI', checked)}
                      />
                    ) : (
                      <span className="text-blue-500">
                        {editableBooking.fetchAAPI ? checksvg : notchecksvg}
                      </span>
                    )}
                  </>
                )}
              </div>

              {editableBooking.API ? <div className="mb-2">
                <span className="font-semibold">API key:</span>
                {isEditMode ? (
                  <Input value={editableBooking.APIkey} onChange={(e) => handleChange('APIkey', e.target.value)} />
                ) : (
                  `$${editableBooking.APIkey}`
                )}

              </div> : ''
              }
              {editableBooking.API ?
                <div className="mb-2">
                  <span className="font-semibold">API URL:</span>
                  {isEditMode ? (
                    <Input value={editableBooking.APIurl} onChange={(e) => handleChange('APIurl', e.target.value)} />
                  ) : (
                    editableBooking.APIurl
                  )}

                </div> : ''
              }
              <div className="mb-2">
                <span className="font-semibold">Description:</span>
                {isEditMode ? (
                  <Input value={editableBooking.description} onChange={(e) => handleChange('description', e.target.value)} />
                ) : (
                  editableBooking.description
                )}

              </div>
              <div className="mb-2">
                <span className="font-semibold">Location:</span>
                {isEditMode ? (
                  <Input value={editableBooking.location} onChange={(e) => handleChange('location', e.target.value)} />
                ) : (
                  editableBooking.location
                )}

              </div>

              <div className="mb-2">
                <span className="font-semibold">Badges:</span>
                {isEditMode ? (
                  <Select
                    mode="tags"
                    placeholder="Please select"
                    value={editableBooking.badge}
                    onChange={(value) => handleChange('badge', value)}
                    style={{ width: '100%' }}
                    options={options}
                  />
                ) : (
                  Array.isArray(editableBooking.badge) && editableBooking.badge.map((badge, index) => (
                    <img key={index} src={badge} alt="badge" className="inline-block h-6 mr-2" />
                  ))
                )}
              </div>
              <div className="mb-2">
                <span className="font-semibold">Price:</span>
                {isEditMode ? (
                  <Input value={editableBooking.price} onChange={(e) => handleChange('price', e.target.value)} />
                ) : (
                  `$${editableBooking.price}`
                )}
              </div>
              <div className="mb-2">
                <span className="font-semibold">Sold:</span>
                {isEditMode ? (
                  <Input value={editableBooking.sold} onChange={(e) => handleChange('sold', e.target.value)} />
                ) : (
                  editableBooking.sold
                )}
              </div>
              <div className="mb-2">
                <span className="font-semibold">Revenue generated:</span>
                {isEditMode ? (
                  <Input value={editableBooking.revenue} onChange={(e) => handleChange('revenue', e.target.value)} />
                ) : (
                  `$${editableBooking.revenue}`
                )}
              </div>
              <div className="mb-2">
                <span className="font-semibold">Reviews:</span>
                {isEditMode ? (
                  <Input value={editableBooking.reviews} onChange={(e) => handleChange('reviews', e.target.value)} />
                ) : (
                  editableBooking.reviews
                )}
              </div>
              <div className="mb-2">
                <span className="font-semibold">Star rating:</span>
                {isEditMode ? (
                  <Rate value={editableBooking.rating} onChange={(value) => handleChange('rating', value)} />
                ) : (
                  editableBooking.rating
                )}
              </div>
              <div className="mb-2">
                <span className="font-semibold">Pick up service:</span>
                {isEditMode ? (
                  <Switch
                    checked={editableBooking.pickup}
                    onChange={(checked) => handleChange('pickup', checked)}
                  />
                ) : (
                  editableBooking.pickup ? 'Available' : 'Not Available'
                )}
              </div>
              <div className="mb-2 flex">
                <span className="font-semibold">Cameras:</span>
                {isEditMode ? (
                  <Switch value={editableBooking.cameras} onChange={(checked) => handleChange('cameras', checked)} />
                ) : (
                  editableBooking.cameras ? checksvg : notchecksvg
                )}
              </div>
              <div className="mb-2">
                <span className="font-semibold">Related trips:</span>
                {isEditMode ? (
                  <Select
                    mode="tags"
                    placeholder="Please select"
                    value={editableBooking.relatedTrips}
                    onChange={(value) => handleChange('relatedTrips', value)}
                    style={{ width: '100%' }}
                    options={relatedTripsOptions}
                  />
                ) : (
                  Array.isArray(editableBooking.relatedTrips) && editableBooking.relatedTrips.map((relatedTrips, index) => (
                    // <img key={index} src={badge} alt="badge" className="inline-block h-6 mr-2" />
                    <p>{relatedTrips}</p>
                  ))
                )}
              </div>
            </div>
            <div>

              <div className="mb-2">
                <span className="font-semibold">About:</span>
                {isEditMode ? (
                  <Input.TextArea value={editableBooking.about} onChange={(e) => handleChange('about', e.target.value)} />
                ) : (
                  <p>{editableBooking.about}</p>
                )}
              </div>
              {/* <div className="mb-2">
                <span className="font-semibold">Highlights:</span>
                {isEditMode ? (
                  <Input.TextArea value={editableBooking.highlights || ''} onChange={(e) => handleChange('highlights', e.target.value)} />
                ) : (
                  <ul className="list-disc pl-5">
                    {editableBooking.highlights?.split(',').map((highlight, index) => <li key={index}>{highlight}</li>)}
                  </ul>
                )}
              </div> */}
              <div className="mb-2">
                <span className="font-semibold">Things to do:</span>
                {isEditMode ? (
                  <ul className="list-disc pl-5">
                    {editableBooking.dothis?.map((item, index) => (
                      <li key={index} className="flex items-center mt-1">
                        <Input
                          value={item || ''}
                          onChange={(e) => handleItemChange('dothis', index, e.target.value)}
                          className="flex-grow"
                        />
                        <Button
                          type="link"
                          onClick={() => handleDeleteItem('dothis', index)}
                          icon={<DeleteOutlined />}
                        />
                      </li>
                    ))}

                    <Button onClick={() => handleAddItem('dothis')} className=' bg-gray-400 mt-1'>Add Item</Button>

                  </ul>
                ) : (
                  <ul className="list-disc pl-5">
                    {editableBooking.dothis?.map((item, index) => <li key={index}>{item}</li>)}
                  </ul>
                )}
              </div>
              <div className="mb-2">
                <span className="font-semibold">Insights:</span>
                {isEditMode ? (
                  <Input.TextArea value={editableBooking.insights || ''} onChange={(e) => handleChange('insights', e.target.value)} />
                ) : (
                  <p>{editableBooking.insights}</p>
                )}
              </div>
              <div className="mb-2">
                <span className="font-semibold">Know before you go:</span>
                {isEditMode ? (
                  <ul className="list-disc pl-5">
                    {editableBooking.knowbefore?.map((item, index) => (
                      <li key={index} className="flex items-center mt-1">
                        <Input
                          value={item || ''}
                          onChange={(e) => handleItemChange(index, e.target.value)}
                          className="flex-grow"
                        />
                        <Button
                          type="link"
                          onClick={() => handleDeleteItem(index)}
                          icon={<DeleteOutlined />}
                        />
                      </li>
                    ))}

                    <Button onClick={handleAddItem} className=' bg-gray-400 mt-1'>Add Item</Button>

                  </ul>
                ) : (
                  <ul className="list-disc pl-5">
                    {editableBooking.knowbefore?.map((item, index) => <li key={index}>{item}</li>)}
                  </ul>
                )}
              </div>

            </div>
          </div>
          <div className="grid grid-cols-1 md:grid-cols-4 gap-4 mt-4">
            <div className=' border border-theblue-400 rounded-md p-3'>
              <div className="font-semibold mb-2">Suitable for:</div>
              <div className="grid grid-cols-1 gap-2">
                {Object.keys(editableBooking.suitablefor || {}).map((key) => (
                  <div key={key} className="flex items-center">
                    <span className="mr-2">{key.charAt(0).toUpperCase() + key.slice(1)}:</span>
                    {isEditMode ? (
                      <Switch
                        size='small'
                        checked={editableBooking.suitablefor[key]}
                        onChange={(checked) => handleChange('suitablefor', { ...editableBooking.suitablefor, [key]: checked })}
                      />
                    ) : (
                      <span>{editableBooking.suitablefor[key] ? checksvg : notchecksvg}</span>
                    )}
                  </div>
                ))}
              </div>
            </div>
            <div className=' border border-theblue-400 rounded-md p-3'>
              <div className="font-semibold mb-2">Included items:</div>
              <div className="grid grid-cols-1 gap-2">
                {Object.keys(editableBooking.includeditems || {}).map((key) => (
                  <div key={key} className="flex items-center">
                    <span className="mr-2">{key.charAt(0).toUpperCase() + key.slice(1)}:</span>
                    {isEditMode ? (
                      <Switch
                        size='small'
                        checked={editableBooking.includeditems[key]}
                        onChange={(checked) => handleChange('includeditems', { ...editableBooking.includeditems, [key]: checked })}
                      />
                    ) : (
                      <span>{editableBooking.includeditems[key] ? checksvg : notchecksvg}</span>
                    )}
                  </div>
                ))}
              </div>
            </div>
            <div className=' border border-theblue-400 rounded-md p-3'>
              <div className="font-semibold mb-2">Present items:</div>
              <div className="grid grid-cols-1 gap-2">
                {Object.keys(editableBooking.presentitems || {}).map((key) => (
                  <div key={key} className="flex items-center">
                    <span className="mr-2">{key.charAt(0).toUpperCase() + key.slice(1)}:</span>
                    {isEditMode ? (
                      <Switch
                        size='small'
                        checked={editableBooking.presentitems[key]}
                        onChange={(checked) => handleChange('presentitems', { ...editableBooking.presentitems, [key]: checked })}
                      />
                    ) : (
                      <span>{editableBooking.presentitems[key] ? checksvg : notchecksvg}</span>
                    )}
                  </div>
                ))}
              </div>
            </div>
            <div className=' border border-theblue-400 rounded-md p-3'>
              <div className="font-semibold mb-2">Available languages:</div>
              <div className="grid grid-cols-1 gap-2">
                {Object.keys(editableBooking.availablelanguages || {}).map((key) => (
                  <div key={key} className="flex items-center">
                    <span className="mr-2">{key.charAt(0).toUpperCase() + key.slice(1)}:</span>
                    {isEditMode ? (
                      <Switch
                        size='small'
                        checked={editableBooking.availablelanguages[key]}
                        onChange={(checked) => handleChange('availablelanguages', { ...editableBooking.availablelanguages, [key]: checked })}
                      />
                    ) : (
                      <span>{editableBooking.availablelanguages[key] ? checksvg : notchecksvg}</span>
                    )}
                  </div>
                ))}
              </div>
            </div>
            <div>
              <div className="font-semibold mb-2">What to bring:</div>
              {isEditMode ? (
                <Input.TextArea value={editableBooking.whattobring.join(', ') || ''} onChange={(e) => handleChange('whattobring', e.target.value.split(', '))} />
              ) : (
                <ul className="list-disc pl-5">
                  {editableBooking.whattobring?.map((item, index) => <li key={index}>{item}</li>)}
                </ul>
              )}
            </div>
          </div>
          <div className="flex justify-end mt-4">
            <Button type="primary" onClick={handleEditClick} className="mr-2">
              {isEditMode ? 'Cancel' : 'Edit'}
            </Button>
            <Button danger  onClick={handleDelete}>
           
              Delete
            </Button>
            {isEditMode && (
              <Button type="primary" onClick={handleSave}>
                Save
              </Button>
            )}
          </div>

        </div>
      )}
    </Modal>
  );
};

export default BookingModal;
