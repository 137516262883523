import React from 'react';
import { Form, Input, Button, Card } from 'antd';
import { useHistory } from 'react-router-dom';

const LogIn = () => {
//   const history = useHistory();

//   const onFinish = (values) => {
//     console.log('Success:', values);
//     // Handle login logic here
//   };

//   const onFinishFailed = (errorInfo) => {
//     console.log('Failed:', errorInfo);
//   };

  return (
    <div className="flex flex-col justify-center items-center h-screen">
        <a className='logo text-5xl text-theblue-400'>TheTrip <br />Curaçao</a>
      <Card title="Login" className="w-full max-w-md text-center mt-5">
        <Form
          name="login"
        //   initialValues={{ remember: true }}
        //   onFinish={onFinish}
        //   onFinishFailed={onFinishFailed}
          layout="vertical"
        >
          <Form.Item
            label="Email"
            name="email"
            rules={[{ required: true, message: 'Please input your email!' }]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="Password"
            name="password"
            rules={[{ required: true, message: 'Please input your password!' }]}
          >
            <Input.Password />
          </Form.Item>

          <Form.Item>
            <Button type="primary" htmlType="submit" block>
              Log In
            </Button>
          </Form.Item>
        </Form>

        <p className="text-center">
          Don't have an account?{' '}
          <a href='/usersignup' className="text-theorange-400 cursor-pointer">
            Sign Up
          </a>
          
        </p>
        <a href="/forgot-password" className="text-theorange-400 cursor-pointer">
              Forgot Password?
            </a>
      </Card>
    </div>
  );
};

export default LogIn;
