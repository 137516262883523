import React, { useRef, useState } from 'react';
import { Table, Modal, Button, Input, Space, message, Form } from 'antd';
import { SearchOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import Highlighter from 'react-highlight-words';

const { confirm } = Modal;

const UserManagement = () => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const [users, setUsers] = useState([
    {
        id: 1,
        name: 'John Doe',
        email: 'john.doe@example.com',
        phone: '1234567890',
        bookedActivities: [
          { title: 'Snorkeling', date: '2023-07-20' },
          { title: 'Hiking', date: '2023-07-21' },
        ],
      },
      {
        id: 2,
        name: 'Jane Smith',
        email: 'jane.smith@example.com',
        phone: '0987654321',
        bookedActivities: [{ title: 'Diving', date: '2023-08-15' }],
      },
      {
        id: 3,
        name: 'Alice Johnson',
        email: 'alice.johnson@example.com',
        phone: '1122334455',
        bookedActivities: [
          { title: 'Kayaking', date: '2023-07-22' },
          { title: 'Camping', date: '2023-07-23' },
        ],
      },
      {
        id: 4,
        name: 'Bob Brown',
        email: 'bob.brown@example.com',
        phone: '2233445566',
        bookedActivities: [{ title: 'Surfing', date: '2023-08-10' }],
      },
      {
        id: 5,
        name: 'Charlie Davis',
        email: 'charlie.davis@example.com',
        phone: '3344556677',
        bookedActivities: [
          { title: 'Paragliding', date: '2023-08-05' },
          { title: 'Rock Climbing', date: '2023-08-06' },
        ],
      },
      {
        id: 6,
        name: 'David Evans',
        email: 'david.evans@example.com',
        phone: '4455667788',
        bookedActivities: [{ title: 'Sailing', date: '2023-09-01' }],
      },
      {
        id: 7,
        name: 'Eva Green',
        email: 'eva.green@example.com',
        phone: '5566778899',
        bookedActivities: [
          { title: 'Jet Skiing', date: '2023-09-10' },
          { title: 'Fishing', date: '2023-09-11' },
        ],
      },
      {
        id: 8,
        name: 'Frank Harris',
        email: 'frank.harris@example.com',
        phone: '6677889900',
        bookedActivities: [{ title: 'Scuba Diving', date: '2023-10-15' }],
      },
      {
        id: 9,
        name: 'Grace Hughes',
        email: 'grace.hughes@example.com',
        phone: '7788990011',
        bookedActivities: [
          { title: 'Windsurfing', date: '2023-10-05' },
          { title: 'Mountain Biking', date: '2023-10-06' },
        ],
      },
      {
        id: 10,
        name: 'Henry Jackson',
        email: 'henry.jackson@example.com',
        phone: '8899001122',
        bookedActivities: [{ title: 'Skydiving', date: '2023-11-12' }],
      },
      {
        id: 11,
        name: 'Ivy King',
        email: 'ivy.king@example.com',
        phone: '9900112233',
        bookedActivities: [
          { title: 'Zip Lining', date: '2023-11-20' },
          { title: 'Snowboarding', date: '2023-11-21' },
        ],
      },
      {
        id: 12,
        name: 'Jack Lewis',
        email: 'jack.lewis@example.com',
        phone: '1011121314',
        bookedActivities: [{ title: 'Hot Air Ballooning', date: '2023-12-01' }],
      },
      {
        id: 13,
        name: 'Katie Miller',
        email: 'katie.miller@example.com',
        phone: '1213141516',
        bookedActivities: [
          { title: 'Bungee Jumping', date: '2023-12-05' },
          { title: 'Horse Riding', date: '2023-12-06' },
        ],
      },
      {
        id: 14,
        name: 'Liam Nelson',
        email: 'liam.nelson@example.com',
        phone: '1314151617',
        bookedActivities: [{ title: 'White Water Rafting', date: '2023-12-15' }],
      },
      {
        id: 15,
        name: 'Mia Owens',
        email: 'mia.owens@example.com',
        phone: '1415161718',
        bookedActivities: [
          { title: 'Skiing', date: '2024-01-10' },
          { title: 'Ice Skating', date: '2024-01-11' },
        ],
      },
      {
        id: 16,
        name: 'Noah Parker',
        email: 'noah.parker@example.com',
        phone: '1516171819',
        bookedActivities: [{ title: 'Cave Exploration', date: '2024-01-15' }],
      },
      {
        id: 17,
        name: 'Olivia Quinn',
        email: 'olivia.quinn@example.com',
        phone: '1617181920',
        bookedActivities: [
          { title: 'Rafting', date: '2024-02-01' },
          { title: 'Waterfall Trek', date: '2024-02-02' },
        ],
      },
      {
        id: 18,
        name: 'Paul Roberts',
        email: 'paul.roberts@example.com',
        phone: '1718192021',
        bookedActivities: [{ title: 'Desert Safari', date: '2024-02-15' }],
      },
      {
        id: 19,
        name: 'Quinn Smith',
        email: 'quinn.smith@example.com',
        phone: '1819202122',
        bookedActivities: [
          { title: 'Sandboarding', date: '2024-03-01' },
          { title: 'Dune Bashing', date: '2024-03-02' },
        ],
      },
      {
        id: 20,
        name: 'Rachel Turner',
        email: 'rachel.turner@example.com',
        phone: '1920212223',
        bookedActivities: [{ title: 'Forest Hiking', date: '2024-03-15' }],
      },
  ]);
  const [form] = Form.useForm();
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const searchInput = useRef(null);

  const handleModalOpen = (user) => {
    setSelectedUser(user);
    form.setFieldsValue(user); // Pre-fill form with selected user details
    setIsModalVisible(true);
  };

  const handleModalCancel = () => {
    setIsModalVisible(false);
    setIsEditing(false);
  };

  const showRemoveConfirm = (userId) => {
    confirm({
      title: 'Are you sure you want to remove this user?',
      icon: <ExclamationCircleOutlined />,
      content: 'This action cannot be undone.',
      okText: 'Yes, remove',
      okType: 'danger',
      cancelText: 'No',
      onOk() {
        handleRemoveUser(userId);
      },
    });
  };

  const handleRemoveUser = (userId) => {
    setUsers(users.filter(user => user.id !== userId));
    message.success('User removed successfully');
    setIsModalVisible(false);
  };

  const handleSaveChanges = () => {
    form.validateFields().then((values) => {
      const updatedUsers = users.map(user =>
        user.id === selectedUser.id ? { ...user, ...values } : user
      );
      setUsers(updatedUsers);
      message.success('User details updated successfully');
      setIsModalVisible(false);
      setIsEditing(false);
    }).catch((info) => {
      console.error('Validation Failed:', info);
    });
  };

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText('');
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
      <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 180 }}
          >
            Search
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? '#1677ff' : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  });

  const columns = [
    {
      title: 'Id',
      dataIndex: 'id',
      key: 'id',
      width: '5%',
      ...getColumnSearchProps('id'),
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      width: '30%',
      ...getColumnSearchProps('name'),
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
      width: '30%',
      ...getColumnSearchProps('email'),
    },
    {
      title: 'Phone',
      dataIndex: 'phone',
      key: 'phone',
      width: '20%',
      ...getColumnSearchProps('phone'),
    },
    {
      title: 'Actions',
      key: 'actions',
      render: (text, record) => (
        <Button type="primary" onClick={() => handleModalOpen(record)}>
          View Details
        </Button>
      ),
    },
  ];

  return (
    <div className="user-management ml-52 pl-3 pr-3 pt-10">
      <h1 className="text-gray-800 font-medium text-xl">User Management</h1>
      <Table columns={columns} dataSource={users} rowKey="id" className="mt-10" />

      <Modal
        title={isEditing ? 'Edit User Details' : 'User Details'}
        visible={isModalVisible}
        onCancel={handleModalCancel}
        footer={[
          isEditing ? (
            <Button key="save" type="primary" onClick={handleSaveChanges}>
              Save Changes
            </Button>
          ) : (
            <Button key="edit" type="primary" onClick={() => setIsEditing(true)}>
              Edit Details
            </Button>
          ),
          <Button key="remove" type="danger" onClick={() => showRemoveConfirm(selectedUser.id)}>
            Remove User
          </Button>,
          <Button key="back" onClick={handleModalCancel}>
            Close
          </Button>,
        ]}
      >
        {selectedUser && (
          <Form form={form} layout="vertical">
            <Form.Item
              name="name"
              label="Name"
              rules={[{ required: true, message: 'Please input the user\'s name!' }]}
            >
              <Input disabled={!isEditing} />
            </Form.Item>
            <Form.Item
              name="email"
              label="Email"
              rules={[{ required: true, message: 'Please input the user\'s email!' }]}
            >
              <Input disabled={!isEditing} />
            </Form.Item>
            <Form.Item
              name="phone"
              label="Phone"
              rules={[{ required: true, message: 'Please input the user\'s phone!' }]}
            >
              <Input disabled={!isEditing} />
            </Form.Item>
            <h3>Booked Activities:</h3>
            <ul>
              {selectedUser.bookedActivities.map((activity, index) => (
                <li key={index}>
                  {activity.title} - {activity.date}
                </li>
              ))}
            </ul>
          </Form>
        )}
      </Modal>
    </div>
  );
};

export default UserManagement;

