import React from 'react'
// import BlueHeader from '../Components/BlueHeader'
import SecondaryHeader from '../Components/SecondaryHeader'
import MainFooter from '../Components/MainFooter'

export default function TermConditions() {
  return (
    <main>
      <SecondaryHeader />
      <section className='p-5 md:p-8 '>
        <h1 className=' text-3xl font-bold text-theblue-400'>Terms & Conditions</h1>
        <p className=' font-normal text-gray-700 text-sm md:text-lg mt-4'>Welcome to TheTripCuraçao. By accessing our website and booking our
          services, you agree to the following terms and conditions. If you do not agree
          with any part of these terms, please do not use <a href="/" className=' underline'>www.thetripcuracao.com </a></p>
        {/* <h1 className=' text-2xl font-bold text-theblue-400'>How do I book a tour?</h1> */}
        <p className=' mt-4 font-extralight text-gray-500 text-sm md:text-lg'><span className='font-medium text-theblue-400'>Service Description:</span> TheTripCuraçao offers a variety of travel and tour
          services. TheTripCuraçao may modify, improve, or discontinue services at its
          discretion without notice. Services may also be provided through subsidiaries
          or affiliated entities.</p>

        <p className=' mt-4 font-extralight text-gray-500 text-sm md:text-lg'><span className='font-medium text-theblue-400'>User/customer:</span> The user/customer is the person who has booked via the
          platform. The customer who makes the reservation guarantees that they are
          authorized to make a reservation for the group they are bringing.</p>

        <p className=' mt-4 font-extralight text-gray-500 text-sm md:text-lg'><span className='font-medium text-theblue-400'>Third Parties:</span> A third party is a recognized company or person that offers a
          service on behalf of TheTripCuraçao or a provider.</p>

        <p className=' mt-4 font-extralight text-gray-500 text-sm md:text-lg'><span className='font-medium text-theblue-400'>Booking and Payment:</span> All bookings must be made through our website
          ( www.thetripcuracao.com ). Full payment is required at the time of booking
          unless otherwise stated. We accept major credit cards and other online
          payment systems for your convenience.</p>

        <p className=' mt-4 font-extralight text-gray-500 text-sm md:text-lg'><span className='font-medium text-theblue-400'>Changes and Modifications:</span> TheTripCuraçao reserves the right to change or
          modify these terms at any time. Users are advised to review these terms
          periodically to stay informed of any updates.</p>

        <p className=' mt-4 font-extralight text-gray-500 text-sm md:text-lg'><span className='font-medium text-theblue-400'>User Responsibilities:</span> Users are expected to adhere to the following
          guidelines while using TheTripCuraçao:</p>
        <ul className='font-extralight text-gray-500 text-sm md:text-lg'>
          <li>1. Users must provide accurate information during the booking process and comply with all local laws and regulations.</li>
          <li>2. Users must not make bookings under someone else's name or impersonate another person.</li>
          <li>3. Users must not approach other users for commercial purposes unrelated to the intended use of the Website.</li>
          <li>4. If you wish to report another user for misuse of the Website, please send an email to <a href="mailto:info@naarcuracao.com">info@naarcuracao.com</a>.</li>
          <li>5. TheTripCuraçao reserves the right to edit or remove information provided by users before it is made public or published on the Website.</li>
        </ul>


      </section>
      <MainFooter/>
    </main>
  )
}
