import React, { useState } from 'react';
import { Button, Modal, Input, message } from 'antd';

function CompanyDirectory() {
  const [users, setUsers] = useState([
    {
      id: 1,
      name: 'John Jacob',
      title: 'Co-founder',
      mail: 'johnmichael@gmail.com',
      phone: '+12 345 6789',
      address: '123 street, 456 lane, Curacao',
      bankAccount: '123456 78901',
      commission: '20%',
      profilePicture: '../../assets/sample-dp-1.png'
    },
    {
      id: 2,
      name: 'Jane Doe',
      title: 'CEO',
      mail: 'janedoe@gmail.com',
      phone: '+12 345 6790',
      address: '124 street, 457 lane, Curacao',
      bankAccount: '123456 78902',
      commission: '25%',
      profilePicture: '../../assets/sample-dp-2.png'
    },
    // Add more user objects here if needed
  ]);

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isAddModalVisible, setIsAddModalVisible] = useState(false);
  const [currentUser, setCurrentUser] = useState(null);
  const [password, setPassword] = useState('');
  const [isPasswordModalVisible, setIsPasswordModalVisible] = useState(false);

  const correctPassword = 'admin123'; // Replace with the actual password

  const showPasswordModal = (user) => {
    setCurrentUser(user);
    setIsPasswordModalVisible(true);
  };

  const handlePasswordOk = () => {
    if (password === correctPassword) {
      setIsPasswordModalVisible(false);
      setIsModalVisible(true);
    } else {
      message.error('Incorrect password');
    }
  };

  const handlePasswordCancel = () => {
    setIsPasswordModalVisible(false);
  };

  const handleOk = () => {
    const updatedUsers = users.map(user => user.id === currentUser.id ? currentUser : user);
    setUsers(updatedUsers);
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const handleAddOk = () => {
    const newUser = { ...currentUser, id: users.length + 1 };
    setUsers([...users, newUser]);
    setIsAddModalVisible(false);
  };

  const handleAddCancel = () => {
    setIsAddModalVisible(false);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setCurrentUser({ ...currentUser, [name]: value });
  };

  const handleRemoveUser = () => {
    setUsers(users.filter(user => user.id !== currentUser.id));
    setIsModalVisible(false);
    message.success('User removed successfully');
  };

  return (
    <main className='ml-52 pl-3 pr-3'>

      <header className='w-full pt-10 flex justify-between'>
        <h1 className='text-gray-800 font-medium text-xl'>Company Directory</h1>
        <Button type="primary" className="bg-blue-600 text-white" onClick={() => { setIsAddModalVisible(true); setCurrentUser({}); }}>
          Add User
        </Button>
      </header>

      <section className=' mt-10 grid grid-cols-3 gap-3'>
        {users.map(user => (
          <div key={user.id} className="bg-white p-6 rounded-bl-2xl rounded-br-2xl rounded-t-[80px] shadow-md max-w-[350px] mb-4">
            <div className="flex items-center gap-5">
              <div className="w-24 h-24 rounded-full bg-gray-300 mb-4"></div>
              <div>
                <h2 className="text-xl font-bold text-gray-800">{user.name}</h2>
                <p className="text-gray-500">{user.title}</p></div>
            </div>
            <hr className="my-4" />
            <div className="text-left space-y-2">
              <div className="flex gap-2">
                <span className="font-semibold text-gray-700">Mail Id:</span>
                <span className="text-gray-600">{user.mail}</span>
              </div>
              <div className="flex gap-2">
                <span className="font-semibold text-gray-700">Phone:</span>
                <span className="text-gray-600">{user.phone}</span>
              </div>
              <div className="flex gap-2">
                <span className="font-semibold text-gray-700">Address:</span>
                <span className="text-gray-600">{user.address}</span>
              </div>
              <div className="flex gap-2">
                <span className="font-semibold text-gray-700">Bank account:</span>
                <span className="text-gray-600">{user.bankAccount}</span>
              </div>
              <div className="flex gap-2">
                <span className="font-semibold text-gray-700">Commission:</span>
                <span className="text-gray-600">{user.commission}</span>
              </div>
            </div>
            <div className="mt-6 text-right">
              <Button type="primary" className="bg-blue-600 text-white" onClick={() => showPasswordModal(user)}>Edit</Button>
            </div>
          </div>
        ))}
      </section>

      <Modal title="Enter Password" visible={isPasswordModalVisible} onOk={handlePasswordOk} onCancel={handlePasswordCancel}>
        <Input.Password placeholder="Password" value={password} onChange={(e) => setPassword(e.target.value)} />
      </Modal>

      <Modal title="Edit User" visible={isModalVisible} onOk={handleOk} onCancel={handleCancel} footer={[
        <Button key="remove" type="danger" onClick={handleRemoveUser}>
          Remove User
        </Button>,
        <Button key="submit" type="primary" onClick={handleOk}>
          Save
        </Button>,
        <Button key="back" onClick={handleCancel}>
          Cancel
        </Button>
      ]}>
        <div className="space-y-4">
          <Input placeholder="Name" name="name" value={currentUser?.name || ''} onChange={handleInputChange} />
          <Input placeholder="Title" name="title" value={currentUser?.title || ''} onChange={handleInputChange} />
          <Input placeholder="Mail" name="mail" value={currentUser?.mail || ''} onChange={handleInputChange} />
          <Input placeholder="Phone" name="phone" value={currentUser?.phone || ''} onChange={handleInputChange} />
          <Input placeholder="Address" name="address" value={currentUser?.address || ''} onChange={handleInputChange} />
          <Input placeholder="Bank Account" name="bankAccount" value={currentUser?.bankAccount || ''} onChange={handleInputChange} />
          <Input placeholder="Commission" name="commission" value={currentUser?.commission || ''} onChange={handleInputChange} />
        </div>
      </Modal>

      <Modal title="Add User" visible={isAddModalVisible} onOk={handleAddOk} onCancel={handleAddCancel}>
        <div className="space-y-4">
          <Input placeholder="Name" name="name" value={currentUser?.name || ''} onChange={handleInputChange} />
          <Input placeholder="Title" name="title" value={currentUser?.title || ''} onChange={handleInputChange} />
          <Input placeholder="Mail" name="mail" value={currentUser?.mail || ''} onChange={handleInputChange} />
          <Input placeholder="Phone" name="phone" value={currentUser?.phone || ''} onChange={handleInputChange} />
          <Input placeholder="Address" name="address" value={currentUser?.address || ''} onChange={handleInputChange} />
          <Input placeholder="Bank Account" name="bankAccount" value={currentUser?.bankAccount || ''} onChange={handleInputChange} />
          <Input placeholder="Commission" name="commission" value={currentUser?.commission || ''} onChange={handleInputChange} />
        </div>
      </Modal>
    </main>
  );
}

export default CompanyDirectory;
