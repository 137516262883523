import React from 'react';

const CompareInfoBox = ({ title, items, hideIcons }) => {
  return (
    <div className='bg-white rounded-2xl   p-5 w-full'>
    <h1 className='font-medium text-gray-600 text-sm'>{title}</h1>
    <div className='mt-2 flex flex-col gap-1'>
      {items.map((item, index) => (
        <div key={index} className='text-gray-500 font-light flex justify-between w-full'>
          <div className='flex gap-2'>
            {!hideIcons && <img src={item.icon} alt={item.label} className='w-5 fill-gray-200' />}
            <p className=' text-sm'>{item.label}</p>
          </div>
          {!hideIcons && item.isAvailable !== undefined && (
            <img 
              src={item.isAvailable ? "../../assets/svg/product-page-box/check.svg" : "../../assets/svg/product-page-box/not-check.svg"} 
              alt={item.isAvailable ? "Check" : "Not Check"} 
              className='w-4' 
            />
          )}
        </div>
      ))}
    </div>
  </div>
  );
};



export default CompareInfoBox;
