import React from 'react';
import { Card, Button, Result } from 'antd';
import { CheckCircleOutlined, } from '@ant-design/icons';
// import './BookingConfirmed.css'; 


function BookingConfirmed() {
  const bookedTrip = {
    image: '../../assets/image//private-jet.png',
    name: 'Island Adventure',
    date: '2024-09-05',
    time: '10:00 AM',
    guests: 4,
    totalCost: '$200',
  };

  return (
    <div className="booking-confirmed-container flex flex-col justify-between items-center">
      {/* <div className="checkmark-animation">
        <CheckCircleOutlined style={{ fontSize: '72px', color: '#4CAF50' }} />
      </div>
      <Card className="trip-details-card" title="Booking Confirmed" bordered={false}>
        <p><strong>Trip Name:</strong> {bookedTrip.name}</p>
        <p><strong>Date:</strong> {bookedTrip.date}</p>
        <p><strong>Time:</strong> {bookedTrip.time}</p>
        <p><strong>Guests:</strong> {bookedTrip.guests}</p>
        <p><strong>Total Cost:</strong> {bookedTrip.totalCost}</p>
      </Card> */}
      <Result
        status="success"
        title="Booking successful"
        subTitle="thank you for doing business with us"
        extra={[
            <Button type="primary" key="console" href='/'>
              Back to home
            </Button>,
            <Button key="buy">Buy Again</Button>,
        ]}
      />
      <div className=" bg-white p-3 md:p-5 w-2/3 rounded-xl shadow-sm" bordered={true}>
        {/* <h1 className=' text-xl font-bold mb-3'>Booking details</h1> */}
        <div className=' flex flex-col md:flex-row gap-4 '>
          <div className=' md:w-1/2'>
            <img src={bookedTrip.image} alt="" className=' rounded-xl' />
          </div>
          <line className=' w-11/12  h-[1px] md:w-[1px] md:h-11/12 bg-gray-200'> </line>
          <div className=' text-slate-800'>

            <p><strong>Trip Name:</strong> {bookedTrip.name}</p>
            <p><strong>Date:</strong> {bookedTrip.date}</p>
            <p><strong>Time:</strong> {bookedTrip.time}</p>
            <p><strong>Guests:</strong> {bookedTrip.guests}</p>
            <p><strong>Total Cost:</strong> {bookedTrip.totalCost}</p>

          </div>
        </div>
      </div>
       
    </div>
  );
}

export default BookingConfirmed;
