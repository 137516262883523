import React from 'react'

function ImageCard({image}) {
  return (
    <div>
      <div className=' flex justify-center items-center flex-col bg-white bg-opacity-85 p-3 gap-5 pb-6 border-2 border-orange-300 shadow-lg'>
        <img src={image} alt="" className=' w-80 h-80 object-cover' />
        <p className='logo text-3xl text-theblue-400 opacity-25 cursor-default text-center'>TheTrip <br />Curaçao</p>
      </div>
    </div>
  )
}

export default ImageCard
