import React, { useState } from 'react';
import { Rate, Button, Modal, Input } from 'antd';
import TextArea from 'antd/es/input/TextArea';

function AdminReview({ newReview, onPublish, onDelete }) {
  const [isReplyModalVisible, setIsReplyModalVisible] = useState(false);
  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);
  const [replyContent, setReplyContent] = useState('');

  const showReplyModal = () => {
    setIsReplyModalVisible(true);
  };

  const handleReplyOk = () => {
    // Handle reply submission logic here (e.g., send reply to server)
    setIsReplyModalVisible(false);
  };

  const handleReplyCancel = () => {
    setIsReplyModalVisible(false);
  };

  const showDeleteModal = () => {
    setIsDeleteModalVisible(true);
  };

  const handleDeleteOk = () => {
    // Handle delete logic here (e.g., delete review from server)
    onDelete(newReview.id); // Call onDelete prop with review id
    setIsDeleteModalVisible(false);
  };

  const handleDeleteCancel = () => {
    setIsDeleteModalVisible(false);
  };

  return (
    <div className='bg-white rounded-2xl p-4 flex flex-col gap-5 mt-4'>
      <div className='flex justify-between'>
        <div className='flex gap-2'>
          <img src={newReview.profile} alt="" className='w-12 rounded-full' />
          <div>
            <p className='text-gray-800'>{newReview.name}</p>
            <p className='text-sm text-gray-500'>{newReview.place}</p>
          </div>
        </div>
        <div className='text-left'>
          <Rate disabled defaultValue={newReview.star} />
          <p className='text-sm text-gray-500'>{newReview.date}</p>
        </div>
      </div>
      <div>
        <p className='text-sm text-gray-800'>{newReview.mail}</p>
        <p className='text-sm text-gray-800'>{newReview.phone}</p>
        <p className='text-sm text-gray-800'>{newReview.activity}</p>
      </div>
      <p className='text-sm md:text-base text-gray-600 font-light'>{newReview.testimonial}</p>
      <div className='w-full flex justify-end gap-3'>
        <Button type='tertiary' onClick={showReplyModal}>Reply</Button>
        <Button type='' onClick={showDeleteModal}>Delete</Button>
        {newReview.view ? (
          <Button style={{ backgroundColor: 'black', color: 'white' }} onClick={onPublish}>Hide</Button>
        ) : (
          <Button type='primary' onClick={onPublish}>Publish</Button>
        )}
      </div>

      {/* Reply Modal */}
      <Modal
        title="Reply to Review"
        visible={isReplyModalVisible}
        onOk={handleReplyOk}
        onCancel={handleReplyCancel}
      >
        {/* Reply form */}
        <Input
        placeholder=' Name of the company'/>
        <TextArea
          value={replyContent}
          onChange={(e) => setReplyContent(e.target.value)}
          placeholder="Write your reply here..."
          rows={4}
          className="w-full border border-gray-300 p-2 rounded mt-3"
        />
      </Modal>

      {/* Delete Modal */}
      <Modal
        title="Confirm Deletion"
        visible={isDeleteModalVisible}
        onOk={handleDeleteOk}
        onCancel={handleDeleteCancel}
        okText="Yes, Delete"
        cancelText="Cancel"
      >
        <p>Are you sure you want to delete this review?</p>
      </Modal>
    </div>
  );
}

export default AdminReview;
