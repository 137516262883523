import React from 'react';
import { Link } from 'react-router-dom';
import { useState } from 'react';
import ImageTrip from '../Components/ImageTrip';
import { DatePicker, Space } from 'antd';
import ImageCard from '../Components/ImageCard';
import MainFooter from '../Components/MainFooter';
import BlueHeader from '../Components/BlueHeader';

function About() {

  return (
    <main className=' '>
      <BlueHeader/>

      {/* hero section */}
      <section className="overflow-hidden md: -z-0 ">
        <div className="absolute inset-0 bg-gradient-to-r from-black to-transparent opacity-75 -mt" ></div>
        <img src='../../assets/about.jpg' alt="Hero" className="w-full h-screen object-cover" />
        <div className=" w-full md:w-2/3 absolute inset-0 flex items-left flex-col justify-center p-8 gap-10">
          <div className=' absolute bottom-16 '>
            <p className=' font-light text-white italic md:pl-3'>About Us</p>
            <h1 class="text-4xl text-center text-white md:text-7xl md:text-left font-extrabold
         ">Discover Our Story</h1>
          </div>
        </div>
      </section>
      {/* end of hero section */}

      <section className=' p-6 md:p-8 font-light text-gray-700 md:text-base flex flex-col gap-5 relative'>
        <p className=' md:w-11/12'>Welcome to TheTripCuraçao, your premier travel agency for exploring the enchanting island of Curaçao. Our mission is to provide unforgettable travel experiences, tailored to meet the unique desires of each traveler. With a passion for excellence and a deep love for Curaçao, we are dedicated to making your dream vacation a reality.</p>
        <p className=' md:w-10/12'>We are a group of passionate, born-and-raised locals dedicated to helping tourists enjoy our island just like we do.We strive to provide visibility of as many tours as possible, ensuring you have diverse options to explore.Our goal is to enhance your travel experience by offering memorable and unique excursions, ensuring you don’t miss out on hidden gems and insider tips that mostly only locals know. We are committed to providing exceptional customer service and making the booking process seamless and hassle-free.Let us guide you through the best of Curaçao with our expert knowledge and love for the island, creating unforgettable memories along the way</p>
        <p className=' font-medium '>CEO, TheTripCuraçao</p>
        <img src="../../assets/sign.png" className=' w-1/2 -mt-4 -ml-4 md:w-1/4 md:-ml-16 md:-mt-16 -z-10 ' alt="" />
      </section>



      <MainFooter />
    </main>
  );
}


export default About;
