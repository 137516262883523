import React from 'react'
import SecondaryHeader from '../Components/SecondaryHeader';
import MainFooter from '../Components/MainFooter';
function Cookies() {
  return (
    <main>
      <SecondaryHeader />
      <section className='p-5 md:p-8 '>
        <h1 className=' text-3xl font-bold text-theblue-400'>Cookies</h1>
        <p className='  font-extralight text-gray-500 text-sm md:text-lg '> This Cookies Policy applies to TheTripCuraçao.</p>


        <div className=' mt-4'>
          <h1 className=' font-medium text-theblue-400 md:text-lg'>What are cookies?</h1>
          <p className='  font-extralight text-gray-500 text-sm md:text-lg '> A cookie is a small text file stored on your browser when you visit our website.
            This information can be sent back to us during subsequent visits, allowing our
            platform to recognize you and enhance your experience.</p>
        </div>

        <div className=' mt-4'>
          <h1 className=' font-medium text-theblue-400 md:text-lg'>Why do we use cookies?</h1>
          <p className='  font-extralight text-gray-500 text-sm md:text-lg '> Cookies ensure that our platform operates quickly and securely. They help us
            understand how our platform is used, enabling us to improve our services. We
            also use cookies to show ads tailored to your interests..</p>
        </div>

        <div className=' mt-4'>
          <h1 className=' font-medium text-theblue-400 md:text-lg'>Managing Cookies</h1>
          <p className='  font-extralight text-gray-500 text-sm md:text-lg '> We obtain your consent before placing any non-essential cookies on your
            device. You can choose to have your computer warn you each time a cookie
            is being sent, or you can choose to disable all cookies. You do this through
            your browser settings (Internet Explorer, Firefox, Safari, etc.). Each browser is
            a bit different, so check your browser&#39;s Help menu to learn the correct way to
            modify your cookies. If you disable cookies, our platform may not function
            optimally. For more information on managing cookies, please refer to your
            browser’s help function.</p>
        </div>

        <h1 className=' text-2xl font-bold text-theblue-400 mt-6'>Types of cookies we use:</h1>

        <p className=' mt-4 font-extralight text-gray-500 text-sm md:text-lg'>We use cookies along with external providers, such as but not limited to
          Google and Microsoft, to gather data about user interactions with ad
          impressions and other ad service features related to our website.</p>

        <p className=' mt-4 font-extralight text-gray-500 text-sm md:text-lg'>
          <span className='font-medium text-theblue-400'>Necessary Cookies:</span> Essential for the website to function properly and to save your preferences. These cookies help us recognize you on subsequent visits, allowing the website to be tailored to your preferences. They also remember your consent for cookie placement, saving you time and enhancing your experience on our website. You can delete necessary cookies through your browser settings.
        </p>

        <p className=' mt-4 font-extralight text-gray-500 text-sm md:text-lg'>
          <span className='font-medium text-theblue-400'>Functional Cookies:</span> Enable the website to save your preferences and provide a more personalized experience. These cookies can also remember your consent for cookie placement, making your interactions with our website more efficient and enjoyable. You can remove functional cookies via your browser settings.
        </p>

        <p className=' mt-4 font-extralight text-gray-500 text-sm md:text-lg'>
          <span className='font-medium text-theblue-400'>Session Cookies:</span> Allow us to see which parts of the website you viewed during your visit. This helps us tailor our services to the browsing behavior of our visitors. These cookies are automatically deleted when you close your web browser.
        </p>

        <p className=' mt-4 font-extralight text-gray-500 text-sm md:text-lg'>
          <span className='font-medium text-theblue-400'>Our Tracking Cookies:</span> With your permission, we place a cookie on your device that can be accessed when you visit a website within our network. This allows us to learn if you have also visited other websites in our network. The resulting profile is not linked to your name, address, email address, or similar information but is solely used to tailor advertisements to your profile, making them as relevant as possible for you.
        </p>

        <p className=' mt-4 font-extralight text-gray-500 text-sm md:text-lg'>
          <span className='font-medium text-theblue-400'>Advertisers' Tracking Cookies:</span> With your permission, our advertisers place tracking cookies on your device. These cookies track which pages you visit within their network to build a profile of your online browsing behavior. This profile is also based on information from your visits to other websites in their network. Like our tracking cookies, this profile is not linked to your personal information as known to us but is used to tailor advertisements to your profile, making them as relevant as possible for you.
        </p>

        <p className=' mt-4 font-extralight text-gray-500 text-sm md:text-lg'>
          <span className='font-medium text-theblue-400'>Social Media Cookies:</span> Allow sharing on social media platforms and help advertisers find you. Our website includes buttons to promote (“like”) or share web pages on social networks like Meta and Google+. These buttons work through code from Meta or Google+, which place cookies. We have no control over this. Please read the privacy policies of Meta and Google+ (which can change regularly) to understand how they handle and store your data processed through these cookies. The information collected is anonymized as much as possible.
        </p>
        <div className=' mt-4'>
          <h1 className=' font-medium text-theblue-400 md:text-lg'>Third-Party Cookies</h1>
          <p className='  font-extralight text-gray-500 text-sm md:text-lg '> We use third-party cookies to enhance your experience on our website. For
            detailed information on how these third parties use your data, please refer to
            their privacy policies:
            <ul>
              <li> <a href="https://policies.google.com/privacy?hl=en-US" className='underline'>Google Privacy Policy</a></li>
              <li><a href="https://privacy.microsoft.com/en-us/privacystatement" className='underline' >Microsoft Privacy Policy</a></li>
              <li> <a href="https://www.facebook.com/privacy/policy/" className='underline'>Meta Privacy Policy</a></li>
              <li><a href="https://policies.google.com/privacy?hl=en-US" className='underline'>Google+ Privacy Policy</a></li>
            </ul>
          </p>
        </div>
        <div className=' mt-4'>
          <h1 className=' font-medium text-theblue-400 md:text-lg'>User Rights and Data Protection</h1>
          <p className='  font-extralight text-gray-500 text-sm md:text-lg '> As a user, you have the right to access, correct, delete, and restrict the
            processing of your personal data collected through cookies. For more
            information on how to exercise these rights, please contact us at
            info@thetripcuracao.com
          </p>
        </div>
        <div className=' mt-4'>
          <h1 className=' font-medium text-theblue-400 md:text-lg'>Contact Information for questions or concerns about your cookie
          policy:</h1>
          <p className=' font-extralight text-gray-500 text-sm md:text-lg'><span className='font-medium '>If you have any questions about this privacy policy, please contact us at:</span> <br /> TheTripcuracao part of Magnet group <br />
            Email: info@thetripcuracao.com<br />
            Thetrip whatsapp: +31687205513<br />
            Curacao contact: + (599)6968704</p>
        </div>
      </section>
      <MainFooter />
    </main>
  )
}

export default Cookies
