import React from 'react'

function Geolocation() {
  return (
    <div className=' border-2 border-theblue-400 w-full rounded-2xl mt-4'>
      {/* <map name=""></map> */}
      <img src="https://www.expertgps.com/images/street-map-sample.png" alt=""  className=' object-cover  rounded-2xl'/>
    </div>
  )
}

export default Geolocation
