import React, { useState } from 'react';
import { Card, Statistic, Row, Col, Table, Button } from 'antd';
import { DatePicker, Space } from 'antd';

import { ArrowUpOutlined, ArrowDownOutlined } from '@ant-design/icons';

const DashboardPage = () => {
  const { RangePicker } = DatePicker;
  const [bookings, setBookings] = useState([
    {
      id: 1,
      title: 'Private Aerial Tour of Curaçao',
      location: 'Willemstad, Curacao',
      price: 432.0,
      date: new Date('2024-07-23'), // example date
      clientName: 'John Doe',
      clientContact: 'john.doe@example.com',
      paymentStatus: 'Paid',
    },
    {
      id: 2,
      title: 'Snorkeling Adventure',
      location: 'Blue Bay, Curacao',
      price: 150.0,
      date: new Date('2024-07-29'), // example date
      clientName: 'Jane Smith',
      clientContact: 'jane.smith@example.com',
      paymentStatus: 'Pending',
    },
    {
      id: 3,
      title: 'Scuba Diving Experience',
      location: 'Mambo Beach, Curacao',
      price: 300.0,
      date: new Date('2024-07-20'), // example date
      clientName: 'Alice Johnson',
      clientContact: 'alice.johnson@example.com',
      paymentStatus: 'Paid',
    },
    // Add more booking data as needed
  ]);

  const [newBookings, setNewBookings] = useState([
    {
      id: 4,
      title: 'Kayaking Expedition',
      location: 'Spanish Water, Curacao',
      price: 200.0,
      date: new Date('2024-07-25'), // example date
      clientName: 'Michael Brown',
      bookingCode: '12345',
      paymentStatus: 'Pending',
    },
    {
      id: 5,
      title: 'Sunset Cruise',
      location: 'Punda, Curacao',
      price: 250.0,
      date: new Date('2024-07-30'), // example date
      clientName: 'Emily Davis',
      bookingCode: '12345',
      paymentStatus: 'Paid',
    },
    // Add more new booking data as needed
  ]);

  const cancelledTripsData = [
    {
      key: '1',
      validity: true,  // true means valid
      tripDate: '2024-09-01',
      dateTime: '2024-08-31 14:00',
      hours: '23h 59m',
      clientName: 'John Doe',
      bookingCode: 'ABC123',
      paymentStatus: 'Paid',
      confirmStatus: 'Pending', // Options: 'Pending', 'Confirmed', 'Declined'
    },
    {
      key: '2',
      validity: false,  // false means invalid
      tripDate: '2024-09-10',
      dateTime: '2024-09-08 09:00',
      hours: '47h 0m',
      clientName: 'Jane Smith',
      bookingCode: 'XYZ456',
      paymentStatus: 'Pending',
      confirmStatus: 'Confirmed', // Already confirmed
    },
    {
      key: '3',
      validity: true,  // true means valid
      tripDate: '2024-09-20',
      dateTime: '2024-09-17 11:00',
      hours: '71h 30m',
      clientName: 'Alice Brown',
      bookingCode: 'DEF789',
      paymentStatus: 'Refunded',
      confirmStatus: 'Declined', // Already declined
    },
  ];




  const columns = [
    {
      title: 'Activity',
      dataIndex: 'title',
      key: 'title',
    },
    {
      title: 'Location',
      dataIndex: 'location',
      key: 'location',
    },
    {
      title: 'Price',
      dataIndex: 'price',
      key: 'price',
      render: (text) => `$${text.toFixed(2)}`,
    },
    {
      title: 'Client Name',
      dataIndex: 'clientName',
      key: 'clientName',
    },
    {
      title: 'Booking code',
      dataIndex: 'bookingCode',
      key: 'bookingCode',
    },
    {
      title: 'Payment Status',
      dataIndex: 'paymentStatus',
      key: 'paymentStatus',
    },
    {
      title: 'Booking date',
      dataIndex: 'date',
      key: 'date',
      render: (text) => new Date(text).toLocaleDateString(),
    },
  ];
  const cancelledTripsColumns = [
    {
      title: 'Cancellation Validity',
      dataIndex: 'validity',
      key: 'validity',
      render: (validity) => (
        <span style={{ color: validity ? 'green' : 'red' }}>
          {validity ? 'Valid' : 'Invalid'}
        </span>
      ),
    },
    {
      title: 'Trip Date',
      dataIndex: 'tripDate',
      key: 'tripDate',
    },
    {
      title: 'Cancellation Requested Date & Time',
      dataIndex: 'dateTime',
      key: 'dateTime',
    },
    {
      title: 'Hours',
      dataIndex: 'hours',
      key: 'hours',
    },
    {
      title: 'Client Name',
      dataIndex: 'clientName',
      key: 'clientName',
    },
    {
      title: 'Booking Code',
      dataIndex: 'bookingCode',
      key: 'bookingCode',
    },
    {
      title: 'Payment Status',
      dataIndex: 'paymentStatus',
      key: 'paymentStatus',
    },
    {
      title: 'Confirmation Status',
      dataIndex: 'confirmStatus',
      key: 'confirmStatus',
      render: (text, record) => {
        if (record.confirmStatus === 'Confirmed' || record.confirmStatus === 'Declined') {
          return <span>{record.confirmStatus}</span>;
        }
        return (
          <div className=' flex'>
            <Button
              type="primary"
              onClick={() => handleConfirm(record.key)}
              style={{ marginRight: 8 }}
            >
              Confirmed
            </Button>
            <Button type="danger" onClick={() => handleDecline(record.key)}>
              Declined
            </Button>
          </div>
        );
      },
    },
  ];

  // Functions to handle button actions
  const handleConfirm = (key) => {
    console.log(`Trip ${key} confirmed.`);
    // Update the confirmStatus in the state or data
  };

  const handleDecline = (key) => {
    console.log(`Trip ${key} declined.`);
    // Update the confirmStatus in the state or data
  };



  const getUpcomingBookings = () => {
    const currentDate = new Date();
    const tenDaysLater = new Date();
    tenDaysLater.setDate(currentDate.getDate() + 10);
    return bookings.filter((booking) => {
      const bookingDate = new Date(booking.date);
      return bookingDate >= currentDate && bookingDate <= tenDaysLater;
    });
  };

  const upcomingBookings = getUpcomingBookings();

  const mostBookedTrips = [
    { id: 1, name: 'Trip to Curacao', bookings: 50 },
    { id: 2, name: 'Island Tour', bookings: 45 },
    { id: 3, name: 'Snorkeling Adventure', bookings: 30 },
    { id: 4, name: 'Snorkeling Adventure', bookings: 30 },
    // Add more trips as needed
  ];


  return (
    <div className="ml-52 pl-3 pr-3 pt-10">
      <div className=' flex w-full justify-between mb-3'>
        <h1 className="text-gray-800 font-medium text-xl mb-5">Dashboard</h1>
        <div className=' absolute right-4 flex gap-3'>

          <RangePicker
            size=' small' />
          <Button type='primary'>Logout</Button>

        </div>

      </div>


      <Row gutter={16} className="mb-5">
        <Col span={6}>
          <Card>
            <Statistic
              title="Total Bookings"
              value={bookings.length}
              valueStyle={{ color: '#3f8600' }}
              prefix={<ArrowUpOutlined />}
              suffix="trips"
            />
          </Card>
        </Col>
        <Col span={6}>
          <Card>
            <Statistic
              title="Total Revenue"
              value={bookings.reduce((acc, booking) => acc + booking.price, 0)}
              valueStyle={{ color: '#cf1322' }}
              prefix={<ArrowDownOutlined />}
              suffix="$"
            />
          </Card>
        </Col>
        <Col span={6}>
          <Card>
            <Statistic
              title="Net profit"
              value={(
                bookings.reduce((acc, booking) => acc + booking.price, 0) /
                bookings.length
              ).toFixed(2)}
              valueStyle={{ color: '#3f8600' }}
              suffix="$"
            />
          </Card>
        </Col>
        <Col span={6}>
          <Card>
            <h3 className=' text-sm text-gray-500'>Most booked tours</h3>  {/* Replace Statistic title with a simple header */}
            <div style={{ maxHeight: '100px', overflowY: 'auto' }}>
              {mostBookedTrips.slice(0, 2).map((trip) => (

                <div key={trip.id}>
                  {/* <div className=' w-full bg-gray-200 h-[1px]'></div> */}
                  <p className=' flex'>{trip.name} - <p className=' text-blue-800'>{trip.bookings} bookings</p></p>
                </div>
              ))}
            </div>
          </Card>
        </Col>
      </Row>
      {/* <Row gutter={16} className="mb-5">
        <Col span={24}>
          <Card style={{ backgroundColor: '#1890ff', color: 'white' }}>
            <h2 className="text-white">New Booking</h2>
            <p className="text-white">Here are your latest bookings.</p>
          </Card>
        </Col>
      </Row> */}
      <h2 className="text-gray-800 font-medium text-lg mb-5">New Bookings</h2>
      <Table
        dataSource={newBookings}
        columns={columns}
        rowKey="id"
        className="bg-white shadow-sm rounded-lg overflow-hidden mb-5"
        pagination={false}
      />


      <h2 className="text-gray-800 font-medium text-lg mb-5">Cancelled trips</h2>
      <Table
        dataSource={cancelledTripsData}
        columns={cancelledTripsColumns}
        rowKey="id"
        className="bg-white shadow-sm rounded-lg overflow-hidden mb-5"
        pagination={false}
      />


      <h2 className="text-gray-800 font-medium text-lg mb-5">Upcoming Bookings in 10 Days</h2>
      <Table
        dataSource={upcomingBookings}
        columns={columns}
        rowKey="id"
        className="bg-white shadow-sm rounded-lg overflow-hidden mb-5"
        pagination={false}
      />
      <h2 className="text-gray-800 font-medium text-lg mb-5">All Bookings</h2>
      <Table
        dataSource={bookings}
        columns={columns}
        rowKey="id"
        className="bg-white shadow-sm rounded-lg overflow-hidden"
      />
    </div>
  );
};

export default DashboardPage;
