import React from 'react';
import { Link, Route, Routes } from 'react-router-dom';
import {
  AppstoreOutlined,
  EditOutlined,
  TeamOutlined,
  StarOutlined,
  BookOutlined,
  DashboardOutlined,
  UserOutlined
} from '@ant-design/icons';
import { Layout, Menu, theme } from 'antd';
import Trips from '../pages/Admin-controls-pages/Trips';

const { Header, Content, Footer, Sider } = Layout;

const items = [
  { icon: DashboardOutlined, label: 'Dashboard', path: '' },
  { icon: AppstoreOutlined, label: 'Trips', path: 'trips' },
  { icon: BookOutlined, label: 'Booking', path: 'booking' },
  { icon: TeamOutlined, label: 'Company Directory', path: 'companydirectory' },
  { icon: UserOutlined, label: 'User Managment', path: 'usermanagment' },
  { icon: StarOutlined, label: 'Reviews', path: 'reviews' },
  { icon: EditOutlined, label: 'Edit Content', path: 'editcontent' },

  
].map((item, index) => ({
  key: String(index + 1),
  icon: React.createElement(item.icon),
  label: <Link to={item.path}>{item.label}</Link>,
}));

function AdminSidebar() {
  const {
    token: { colorBgContainer, borderRadiusLG },
  } = theme.useToken();

  return (
    <Layout hasSider>
      <Sider
        style={{
          overflow: 'auto',
          height: '100vh',
          position: 'fixed',
          left: 0,
          top: 0,
          bottom: 0,
          backgroundColor: colorBgContainer,
        }}
      >
        <div className=' w-full flex justify-center items-center p-8'>
          <a href="" className="logo w-24 text-3xl text-theblue-400">
            TheTrip <br /> Curaçao
          </a>
        </div>

        <Menu theme="light" mode="inline" defaultSelectedKeys={['1']} items={items} />
      </Sider>
      <Layout
        style={{
          marginLeft: 200,
          padding: '0 24px 24px',
          backgroundColor: '#FCF1DA'
        }}
      >
        <Content >
          <div>
            <Routes>
              {/* <Route path="" element={<Trips />} /> */}
              {/* <Route path="videos" element={<div>Videos Content</div>} />
              <Route path="upload" element={<div>Upload Content</div>} />
              <Route path="reports" element={<div>Reports Content</div>} />
              <Route path="cloud" element={<div>Cloud Content</div>} />
              <Route path="apps" element={<div>Apps Content</div>} />
              <Route path="teams" element={<div>Teams Content</div>} />
              <Route path="shop" element={<div>Shop Content</div>} /> */}
            </Routes>
          </div>
        </Content>

      </Layout>
    </Layout>
  );
}

export default AdminSidebar;
