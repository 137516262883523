import React from 'react'
import { useState } from 'react';
import { useEffect } from 'react';
import ImageCard from '../Components/ImageCard';
import MainFooter from '../Components/MainFooter';
import BlueHeader from '../Components/BlueHeader';

function LocalExperience() {


  const [menuOpen, setMenuOpen] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);
  //menu-functions
  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };
  //menu-functions

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 50) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);
  return (
    <main>
      <BlueHeader/>
      
      <section className=" overflow-hidden -z-0 h-screen flex justify-center md:justify-between items-center relative" >
        <div className="absolute inset-0 bg-gradient-to-r bg-black" ></div>
        <img src='../../assets/local-experience.jpg' alt="Hero" className=" opacity-50 md:opacity-100 md:w-1/4 object-cover absolute z-5  md:right-28" />
        <div className=" w-full md:w-2/3 absolute inset-0 flex items-left flex-col justify-center p-8 gap-3">
          <p className='text-center md:text-left text-white italic font-light'>Local Experience</p>
          <h1 class="text-4xl text-center text-white md:text-5xl md:text-left font-black ">Experience Curaçao <br />Like a Local</h1>
          <p className='text-sm text-center md:text-left md:text-lg text-white font-light md:w-9/12 opacity-65 mt-4'>
            Immerse yourself in the vibrant and authentic spirit of Curaçao by living like a local. This island offers more than just beautiful beaches and clear waters—it boasts a rich culture, friendly people, and hidden gems that make your visit truly unforgettable.
          </p>
        </div>
      </section>

      <section className='p-8 relative flex mt-16 justify-center'>
        <div className=' h-[700px]  border-2 rounded-3xl border-theblue-400 p-7  bg-[#FDEAC4] flex flex-col justify-between md:flex-row md:justify-between relative md:h-fit items-center md:pt-16 md:pb-16 w-11/12 md:pl-20'>
          {/* image */}
          <div className=' flex flex-col gap-3 md:w-3/5'>
            <h1 className=' font-semibold text-lg md:text-3xl text-theblue-400 opacity-60 md:w-9/12'>Local experience season special</h1>
            <p className=' text-sm md:text-base md:w-9/12 font-light text-gray-700'>Explore Curacao's vibrant culture and seasonal trends with our specially curated package. Immerse yourself in local traditions, savor the cuisine, and take in the stunning natural beauty of the island. Book now for an authentic Caribbean adventure!</p>
            <button className='bluebutton h-10 text-white hover:opacity-85 transition-all md:w-1/3'>I want to go</button>
          </div>
          <div className='  w-full md:w-2/5 flex justify-center items-center'>
            <div className=' flex justify-center items-center flex-col bg-white  p-3 gap-5 pb-6 border-2 border-orange-300 shadow-lg rotate-6 w-fit absolute -bottom-10   md:-bottom-10'>
              <img src='../../assets/local-special.jpg' alt="" className=' w-80 h-80 object-cover' />
              <p className='logo text-3xl text-theblue-400 opacity-25 cursor-default text-center'>TheTrip <br />Curaçao</p>
            </div>

            <div className=' flex justify-center items-center flex-col bg-white p-3 gap-5 pb-6 border-2 border-orange-300 shadow-lg -rotate-3 w-fit absolute -bottom-10   md:-bottom-10'>
              <img src='../../assets/local-special.jpg' alt="" className=' w-80 h-80 object-cover' />
              <p className='logo text-3xl text-theblue-400 opacity-25 cursor-default text-center '>TheTrip <br />Curaçao</p>
            </div>
          </div>

        </div>

      </section>

      {/* carnival */}
      <section className=' mt-8 p-5 md:p-8 flex justify-center'>
        <div className=' flex gap-5 md:mt-20 flex-col  md:flex-row justify-around items-center border-2 border-theblue-400 w-full rounded-xl px-6 py-6 md:px-0 md:py-10 md:w-11/12'>
          <ImageCard image='../../assets/carnival.webp' />
          <div className=' w-full md:w-1/2 flex flex-col  gap-5 '>
            <h1 className=' text-2xl font-bold text-theblue-400'>Party with a Local</h1>
            <p className=' font-extralight text-gray-500'>Curacao’s Nightlife is a tapestry of lively rhythms, infectious energy, and unforgettable celebrations. Heres a sneak peek into the parties that locals rave about.</p>
            <a href="/partywithlocal" className=' mybutton w-fit text-slate-50 border rounded-xl p-3 pl-6 pr-6 flex justify-center items-center text-lg'>Read more</a>
          </div>
        </div>
      </section >
      {/* carnival */}

      {/* menu */}
      <section className=' mt-8 p-5 md:p-8 flex justify-center'>
        <div className=' flex gap-5 md:mt-20  flex-col  md:flex-row-reverse justify-around items-center border-2 border-theblue-400 w-full rounded-xl px-6 py-6 md:px-0 md:py-10 md:w-11/12 '>
          <ImageCard image='../../assets/curacao-menu.jpg' />
          <div className=' w-full md:w-1/2 flex flex-col  gap-5 '>
            <h1 className=' text-2xl font-bold text-theblue-400'>Kuminda di kas experience</h1>
            <p className=' font-extralight text-gray-500'>Curacao's culinary scene is a feast for the senses, blending local traditions with diverse influences. Immerse yourself in the island’s gastronomic delights, including the unique TheTripCuracao kuminda di kas experience</p>
            <a href="/Kumindadikasexperience" className=' mybutton w-fit text-slate-50 border rounded-xl p-3 pl-6 pr-6 flex justify-center items-center text-lg'>give me a taste</a>
          </div>
        </div>
      </section >
      {/* menu */}

      {/* menu */}
      <section className=' mt-8 p-5 md:p-8 flex justify-center'>
      <div className=' flex gap-5 md:mt-20 flex-col  md:flex-row justify-around items-center border-2 border-theblue-400 w-full rounded-xl px-6 py-6 md:px-0 md:py-10 md:w-11/12'>
          <ImageCard image='../../assets/localboy.jpg' />
          <div className=' w-full md:w-1/2 flex flex-col  gap-5 '>
            <h1 className=' text-2xl font-bold text-theblue-400'>Leisure with locals</h1>
            <p className=' font-extralight text-gray-500'>Unveil Curaçao’s leisurely charms.Embark on spirited matches of soccer with the locals, delve into the intricate world of domino culture or witness the passion of young baseball talents.</p>
            <a href="/leisurewithlocals" className=' mybutton w-fit text-slate-50 border rounded-xl p-3 pl-6 pr-6 flex justify-center items-center text-lg'>Discover more</a>
          </div>
        </div>
      </section >
      {/* menu */}

      <section className='relative h-screen '>
        <div className="absolute  inset-0 bg-gradient-to-r from-black to-transparent opacity-75 z-5" ></div>
        <img src='../../assets/special-request.jpg' alt="Hero" className="w-full h-full object-cover z-0" />
        <div className=" w-full md:w-2/3 absolute inset-0 flex items-left flex-col justify-center p-8 gap-5 ">

          <p className='text-center md:text-left text-white italic font-light'>Special request</p>

          <h1 class="text-4xl text-center text-white md:text-6xl md:text-left font-extrabold ">Personalize Your<br />Curaçao Adventure</h1>

          <p className='text-center  md:text-left font-extralight text-white md:w-9/12'>We’d love to hear from you! Whether you have questions about our tours, need help planning your trip, or want to share feedback, our team is here to assist you.</p>

          <p className='text-center  md:text-left font-extralight text-white md:w-10/12'>most popular requests from our guests:
            <li>Special arrangements prior to arrival</li>
            <li>Wedding planner suggestions</li>
            <li>Tasks that requires the knowledge and understanding of a born and raised local
              security</li>
          </p>

          <div className=' flex gap-2'>
            <input type="text" placeholder='Your Name' className='w-full pl-5 bg-white bg-opacity-5 backdrop-blur-sm p-4 md:w-2/5 placeholder:font-extralight placeholder:text-white border-2 border-theorange-400 rounded-lg placeholder:italic text-white' />
            <input type="text" placeholder='Mail Id' className='w-full pl-5 bg-white bg-opacity-5 backdrop-blur-sm p-4 md:w-2/5 placeholder:font-extralight placeholder:text-white border-2 border-theorange-400 rounded-lg placeholder:italic text-white' />
          </div>
          <input type="text" placeholder='Describe your request' className='w-full pl-5 bg-white bg-opacity-5 backdrop-blur-sm p-4 md:w-2/5 placeholder:font-extralight placeholder:text-white border-2 border-theorange-400 rounded-lg placeholder:italic text-white' />

          <a href="" className=' w-full mybutton md:w-1/3 text-slate-50 border border-white rounded-xl p-3 flex justify-center items-center text-lg'>Send a request</a>


        </div>
      </section>
      

      <MainFooter />

    </main>
  )
}

export default LocalExperience
