import React from 'react';
import { Form, Input, Button, Card } from 'antd';
import { useHistory } from 'react-router-dom';

const Signup = () => {
//   const history = useHistory();

//   const onFinish = (values) => {
//     console.log('Success:', values);
//     // Handle signup logic here
//     // After successful signup, redirect to the login page
//     history.push('/userlogin');
//   };

//   const onFinishFailed = (errorInfo) => {
//     console.log('Failed:', errorInfo);
//   };

  return (
    <div className="flex flex-col justify-center items-center h-screen">
        <a className='logo text-5xl text-theblue-400'>TheTrip <br />Curaçao</a>
      <Card title="Sign Up" className="w-full max-w-md mt-3">
        <Form
          name="signup"
        //   initialValues={{ remember: true }}
        //   onFinish={onFinish}
        //   onFinishFailed={onFinishFailed}
          layout="vertical"
        >
          <Form.Item
            label="Username"
            name="username"
            rules={[{ required: true, message: 'Please input your username!' }]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="Email"
            name="email"
            rules={[{ required: true, message: 'Please input your email!' }]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="Password"
            name="password"
            rules={[{ required: true, message: 'Please input your password!' }]}
          >
            <Input.Password />
          </Form.Item>

          <Form.Item>
            <Button type="primary" htmlType="submit" block>
              Sign Up
            </Button>
          </Form.Item>
        </Form>

        <div className="text-center">
          <p>
            Already have an account?{' '}
            <a href="/userlogin" className="text-theorange-400 cursor-pointer">
              Log In
            </a>
          </p>
          <p>
            
          </p>
        </div>
      </Card>
    </div>
  );
};

export default Signup;
