import React, { useState } from 'react';
import { EditOutlined, CloseCircleOutlined } from '@ant-design/icons';
import { Input, Select, AutoComplete, DatePicker, Button } from 'antd';
import BookingModal from '../../Components/BookingModal';
import AddTripModal from '../../Components/AddTripModal';

const { Option } = Select;

const bookingsData = [
  {
    id: 1,
    title: 'Private Aerial Tour of Curaçao',
    category: 'Beaches',
    API: 'true',
    APIkey: 'bjvasuhg',
    APIurl: 'ww./kjkjbnsd/sdf',
    fetchAAPI: false,
    immediateBooking: true,
    location: 'Willemstad, Curacao',
    price: 432.00,
    description: 'Discover the island’s breathtaking beauty with a personalized plane tour.',
    age: { min: '12', max: '45' },
    pickup: true,
    pickupprice: 23.00,
    pickupdirection: 'from there go here and then be here to reach there and turn left',
    eticket: true,
    languages: ['English', 'Español', 'Deutsch'],
    badgeName: 'Top-rated',
    badge: 'Top rated',
    rating: '4',
    camera: false,
    relatedTrips: ['snorkeling', 'jumping', 'running'],
    thumImg: '../../assets/image//private-jet.png',
    images: [
      // '../../assets/image//private-jet.png',
      '../../assets/image//private-jet-2.png',
      '../../assets/image//private-jet-3.png',
      '../../assets/image//private-jet-4.png',
    ],
    about: 'Experience Curaçao from above with our Exclusive Private Aerial Tour. Soar over crystal-clear waters, vibrant coral reefs, and picturesque landscapes in a private plane. Guided by an experienced pilot, this intimate and personalized tour lets you enjoy stunning views and discover hidden gems. Perfect for special occasions or a unique sightseeing adventure, this tour offers an unforgettable perspective of the island.',
    dothis: [
      'Enjoy stunning aerial views of Curaçao.',
      'Capture unique and breathtaking photos.',
      'Experience personalized service with a private plane.',
      'Discover hidden gems with an expert guide.',
      'Perfect for celebrating special occasions.',
    ],
    suitablefor: {
      group: false,
      single: true,
      children: true,
      couple: true
    },
    includeditems: {
      photos: true,
      video: false,
      water: false,
    },
    presentitems: {
      requiredequipment: true,
      chairs: false,
      wc: true,
    },
    availablelanguages: {
      english: true,
      dutch: false,
      papiamento: true,
      spanish: false,
    },
    whattobring: [
      'Comfortable Shoes',
      'Weather appropriate clothing',
    ],
    insights: 'The "Curaçao Jet Center" is our VIP terminal located adjacent to the Main Airport Terminal. It provides complimentary secure parking for all guests. Upon your arrival at the scheduled time, a member of our Customer Service team will be ready to greet you at the reception area.',
    knowbefore: [
      'babies aged 0-11 months for free',
      'children 1-4 get a special price',
      'children has to sit o laps',
      'be present 15 minutes earlier',
    ],
    sold: 239,
    revenue: 53775,
    reviews: 213,
    starRating: 4.3,
  },
  {
    id: 2,
    title: 'Snorkeling Adventure',
    category: 'Beaches',
    API: 'true',
    APIkey: 'bjvasuhg',
    APIurl: 'ww./kjkjbnsd/sdf',
    fetchAAPI: true,
    immediateBooking: false,
    location: 'Playa Lagun, Curacao',
    price: 120.00,
    description: 'Explore the vibrant underwater world with a guided snorkeling tour.',
    age: { min: '10', max: '60' },
    pickup: false,
    eticket: true,
    languages: ['English', 'Español'],
    badgeName: 'Eco-friendly',
    badge: 'Eco friendly',
    rating: '5',
    camera: true,
    relatedTrips: ['kayaking', 'diving', 'swimming'],
    thumImg: '../../assets/image//private-jet.png',
    images: [
      // '../../assets/image//private-jet.png',
      '../../assets/image//private-jet-2.png',
      '../../assets/image//private-jet-3.png',
      '../../assets/image//private-jet-4.png',
    ],
    about: 'Join our Snorkeling Adventure and dive into the crystal-clear waters of Playa Lagun. Discover colorful coral reefs and swim alongside diverse marine life. Perfect for both beginners and experienced snorkelers, this tour provides all necessary equipment and a professional guide to ensure your safety and enjoyment.',
    dothis: [
      'Explore vibrant coral reefs.',
      'Swim with tropical fish.',
      'Learn from experienced guides.',
      'Enjoy a safe and fun experience.',
      'Capture underwater photos.',
    ],
    suitablefor: {
      group: true,
      single: true,
      children: true,
    },
    includeditems: {
      photos: true,
      video: false,
      water: true,
    },
    presentitems: {
      requiredequipment: true,
      chairs: false,
      wc: true,
    },
    availablelanguages: {
      english: true,
      dutch: false,
      papiamento: false,
      spanish: true,
    },
    whattobring: [
      'Swimwear',
      'Towel',
      'Sunscreen',
    ],
    insights: 'Meet at the designated location 10 minutes before the tour starts. All equipment will be provided on-site.',
    knowbefore: [
      'Children under 10 must be accompanied by an adult.',
      'No prior snorkeling experience required.',
      'Life vests are available for all participants.',
      'Tours may be canceled in case of bad weather.',
    ],
    sold: 150,
    revenue: 18000,
    reviews: 98,
    starRating: 4.8,
  },
  {
    id: 3,
    title: 'Sunset Sailing Cruise',
    category: 'Beaches',
    API: 'true',
    APIkey: 'bjvasuhg',
    APIurl: 'ww./kjkjbnsd/sdf',
    fetchAAPI: true,
    immediateBooking: true,
    location: 'Willemstad, Curacao',
    price: 150.00,
    description: 'Relax and enjoy the stunning sunset over the Caribbean Sea on a luxurious sailboat.',
    age: { min: '8', max: '70' },
    pickup: true,
    pickupprice: 10.00,
    pickupdirection: 'Hotel pickup available upon request.',
    eticket: true,
    languages: ['English', 'Dutch'],
    badgeName: 'Popular',
    badge: 'Popular',
    rating: '4.5',
    camera: true,
    relatedTrips: ['sailing', 'dining', 'romance'],
    thumImg: '../../assets/image//private-jet.png',
    images: [
      // '../../assets/image//private-jet.png',
      '../../assets/image//private-jet-2.png',
      '../../assets/image//private-jet-3.png',
      '../../assets/image//private-jet-4.png',
    ],
    about: 'Join us for a breathtaking Sunset Sailing Cruise. Departing from Willemstad, our luxurious sailboat offers the perfect setting to unwind and enjoy the beauty of a Caribbean sunset. Whether you’re celebrating a special occasion or simply looking to relax, this cruise provides a memorable experience with stunning views, delicious snacks, and refreshing drinks.',
    dothis: [
      'Enjoy a beautiful sunset over the sea.',
      'Relax on a luxurious sailboat.',
      'Indulge in complimentary snacks and drinks.',
      'Capture memorable photos.',
      'Perfect for romantic evenings.',
    ],
    suitablefor: {
      group: true,
      single: true,
      children: false,
    },
    includeditems: {
      photos: true,
      video: false,
      water: true,
    },
    presentitems: {
      requiredequipment: false,
      chairs: true,
      wc: true,
    },
    availablelanguages: {
      english: true,
      dutch: true,
      papiamento: false,
      spanish: false,
    },
    whattobring: [
      'Light jacket',
      'Camera',
      'Sunglasses',
    ],
    insights: 'Meet at the harbor 20 minutes before departure. Complimentary snacks and drinks are provided onboard.',
    knowbefore: [
      'Arrive at least 20 minutes before departure.',
      'Wear comfortable clothing.',
      'Bring a light jacket as it may get cool.',
      'Tour is subject to weather conditions.',
    ],
    sold: 175,
    revenue: 26250,
    reviews: 120,
    starRating: 4.7,
  },
  {
    id: 4,
    title: 'Hiking Adventure in Shete Boka National Park',
    category: 'Beaches',
    API: 'true',
    APIkey: 'bjvasuhg',
    APIurl: 'ww./kjkjbnsd/sdf',
    fetchAAPI: true,
    immediateBooking: true,
    location: 'Shete Boka, Curacao',
    price: 75.00,
    description: 'Explore the rugged coastline and dramatic landscapes of Shete Boka National Park on foot.',
    age: { min: '10', max: '65' },
    pickup: false,
    eticket: true,
    languages: ['English', 'Papiamento'],
    badgeName: 'Adventure',
    badge: 'Adventure',
    rating: '4.9',
    camera: true,
    relatedTrips: ['walking', 'nature', 'photography'],
    thumImg: '../../assets/image//private-jet.png',
    images: [
      // '../../assets/image//private-jet.png',
      '../../assets/image//private-jet-2.png',
      '../../assets/image//private-jet-3.png',
      '../../assets/image//private-jet-4.png',
    ],
    about: 'Discover the natural beauty of Shete Boka National Park with our guided Hiking Adventure. Follow scenic trails along the coastline, explore hidden coves, and witness the power of the ocean at blowholes. This tour is perfect for nature enthusiasts and offers plenty of opportunities for photography and wildlife spotting.',
    dothis: [
      'Hike through scenic trails.',
      'Explore hidden coves and beaches.',
      'Witness dramatic blowholes.',
      'Spot local wildlife.',
      'Enjoy a guided tour with expert commentary.',
    ],
    suitablefor: {
      group: true,
      single: true,
      children: true,
    },
    includeditems: {
      photos: false,
      video: false,
      water: true,
    },
    presentitems: {
      requiredequipment: true,
      chairs: false,
      wc: false,
    },
    availablelanguages: {
      english: true,
      dutch: false,
      papiamento: true,
      spanish: false,
    },
    whattobring: [
      'Hiking boots',
      'Hat',
      'Sunscreen',
    ],
    insights: 'Please wear sturdy hiking boots and bring plenty of water. The tour includes several stops for rest and photography.',
    knowbefore: [
      'Children under 10 must be accompanied by an adult.',
      'Wear comfortable hiking boots.',
      'Bring sunscreen and a hat.',
      'Tour may be canceled in case of bad weather.',
    ],
    sold: 200,
    revenue: 15000,
    reviews: 180,
    starRating: 4.9,
  },
  {
    id: 5,
    title: 'ATV Off-Road Adventure',
    category: 'Beaches',
    API: 'true',
    APIkey: 'bjvasuhg',
    APIurl: 'ww./kjkjbnsd/sdf',
    fetchAAPI: true,
    immediateBooking: false,
    location: 'Christoffel National Park, Curacao',
    price: 220.00,
    description: 'Experience the thrill of off-road driving with our ATV adventure through Christoffel National Park.',
    age: { min: '16', max: '60' },
    pickup: true,
    pickupprice: 15.00,
    pickupdirection: 'Pickup available from major hotels.',
    eticket: true,
    languages: ['English', 'Dutch', 'Papiamento'],
    badgeName: 'Thrill-seeker',
    badge: 'Thrill seeker',
    rating: '5',
    camera: true,
    relatedTrips: ['off-road', 'adventure', 'nature'],
    thumImg: '../../assets/image//private-jet.png',
    images: [
      // '../../assets/image//private-jet.png',
      '../../assets/image//private-jet-2.png',
      '../../assets/image//private-jet-3.png',
      '../../assets/image//private-jet-4.png',
    ],
    about: 'Join our ATV Off-Road Adventure and explore the rugged terrain of Christoffel National Park. Navigate through challenging trails, enjoy breathtaking views, and experience the thrill of off-road driving. Our professional guides ensure a safe and exciting adventure for all participants.',
    dothis: [
      'Drive an ATV through rugged terrain.',
      'Explore scenic trails.',
      'Enjoy the thrill of off-road driving.',
      'Take in stunning views.',
      'Experience a guided adventure.',
    ],
    suitablefor: {
      group: true,
      single: true,
      children: false,
    },
    includeditems: {
      photos: true,
      video: false,
      water: true,
    },
    presentitems: {
      requiredequipment: true,
      chairs: false,
      wc: true,
    },
    availablelanguages: {
      english: true,
      dutch: true,
      papiamento: true,
      spanish: false,
    },
    whattobring: [
      'Closed-toe shoes',
      'Comfortable clothing',
      'Sunglasses',
    ],
    insights: 'Please wear closed-toe shoes and bring sunglasses. Helmets and safety gear are provided.',
    knowbefore: [
      'Drivers must be at least 16 years old.',
      'Wear closed-toe shoes.',
      'Bring sunglasses and sunscreen.',
      'Tour is subject to weather conditions.',
    ],
    sold: 220,
    revenue: 48400,
    reviews: 190,
    starRating: 5.0,
  },
  {
    id: 6,
    title: 'Scuba Diving Expedition',
    category: 'Beaches',
    API: 'true',
    APIkey: 'bjvasuhg',
    APIurl: 'ww./kjkjbnsd/sdf',
    fetchAAPI: true,
    immediateBooking: false,
    location: 'Blue Bay, Curacao',
    price: 300.00,
    description: 'Dive into the crystal-clear waters of Blue Bay and explore the underwater wonders of Curacao.',
    age: { min: '18', max: '55' },
    pickup: false,
    eticket: true,
    languages: ['English', 'Español', 'Deutsch'],
    badgeName: 'Explorer',
    badge: 'Explorer',
    rating: '4.8',
    camera: true,
    relatedTrips: ['diving', 'marine life', 'adventure'],
    thumImg: '../../assets/image//private-jet.png',
    images: [
      // '../../assets/image//private-jet.png',
      '../../assets/image//private-jet-2.png',
      '../../assets/image//private-jet-3.png',
      '../../assets/image//private-jet-4.png',
    ],
    about: 'Join our Scuba Diving Expedition and explore the vibrant underwater world of Blue Bay. Dive sites feature colorful coral reefs, diverse marine life, and fascinating underwater landscapes. Our professional instructors provide comprehensive training and ensure a safe and enjoyable experience for divers of all levels.',
    dothis: [
      'Dive into clear blue waters.',
      'Explore vibrant coral reefs.',
      'Swim with diverse marine life.',
      'Learn from professional instructors.',
      'Capture underwater memories.',
    ],
    suitablefor: {
      group: true,
      single: true,
      children: false,
    },
    includeditems: {
      photos: true,
      video: true,
      water: true,
    },
    presentitems: {
      requiredequipment: true,
      chairs: false,
      wc: true,
    },
    availablelanguages: {
      english: true,
      dutch: false,
      papiamento: false,
      spanish: true,
    },
    whattobring: [
      'Swimwear',
      'Towel',
      'Sunscreen',
    ],
    insights: 'Please arrive at the dive center 30 minutes before the scheduled dive. All equipment will be provided.',
    knowbefore: [
      'Divers must be at least 18 years old.',
      'No prior diving experience required.',
      'Participants must be in good health.',
      'Tours may be canceled in case of bad weather.',
    ],
    sold: 180,
    revenue: 54000,
    reviews: 160,
    starRating: 4.8,
  },
  {
    id: 7,
    title: 'Kayaking and Beach Picnic',
    category: 'Beaches',
    API: 'true',
    APIkey: 'bjvasuhg',
    APIurl: 'ww./kjkjbnsd/sdf',
    fetchAAPI: true,
    immediateBooking: false,
    location: 'Cas Abao Beach, Curacao',
    price: 140.00,
    description: 'Paddle along the coastline in a kayak and enjoy a relaxing picnic on the beach.',
    age: { min: '12', max: '50' },
    pickup: true,
    pickupprice: 12.00,
    pickupdirection: 'Pickup available from selected locations.',
    eticket: true,
    languages: ['English', 'Dutch', 'Papiamento'],
    badgeName: 'Relaxing',
    badge: 'Relaxing',
    rating: '4.6',
    camera: true,
    relatedTrips: ['kayaking', 'beach', 'picnic'],
    thumImg: '../../assets/image//private-jet.png',
    images: [
      // '../../assets/image//private-jet.png',
      '../../assets/image//private-jet-2.png',
      '../../assets/image//private-jet-3.png',
      '../../assets/image//private-jet-4.png',
    ],
    about: 'Join our Kayaking and Beach Picnic tour for a relaxing and fun-filled day. Paddle along the beautiful coastline of Cas Abao Beach and enjoy a delicious picnic on the sandy shore. This tour is perfect for families, couples, and anyone looking to unwind and enjoy the natural beauty of Curacao.',
    dothis: [
      'Paddle along the coastline.',
      'Relax on a sandy beach.',
      'Enjoy a delicious picnic.',
      'Swim in crystal-clear waters.',
      'Capture memorable moments.',
    ],
    suitablefor: {
      group: true,
      single: true,
      children: true,
    },
    includeditems: {
      photos: false,
      video: false,
      water: true,
    },
    presentitems: {
      requiredequipment: true,
      chairs: true,
      wc: true,
    },
    availablelanguages: {
      english: true,
      dutch: true,
      papiamento: true,
      spanish: false,
    },
    whattobring: [
      'Swimwear',
      'Towel',
      'Sunscreen',
    ],
    insights: 'Meet at the designated location 15 minutes before the tour starts. All equipment and picnic items will be provided.',
    knowbefore: [
      'Children under 12 must be accompanied by an adult.',
      'Wear swimwear and bring a towel.',
      'Bring sunscreen and a hat.',
      'Tour may be canceled in case of bad weather.',
    ],
    sold: 130,
    revenue: 18200,
    reviews: 110,
    starRating: 4.6,
  },
  {
    id: 8,
    title: 'Historical Walking Tour',
    category: 'Beaches',
    API: 'true',
    APIkey: 'bjvasuhg',
    APIurl: 'ww./kjkjbnsd/sdf',
    fetchAAPI: true,
    immediateBooking: true,
    location: 'Punda, Willemstad, Curacao',
    price: 50.00,
    description: 'Discover the rich history and culture of Willemstad on a guided walking tour.',
    age: { min: '5', max: '70' },
    pickup: false,
    eticket: true,
    languages: ['English', 'Dutch', 'Papiamento'],
    badgeName: 'Cultural',
    badge: 'Cultural',
    rating: '4.4',
    camera: true,
    relatedTrips: ['walking', 'history', 'culture'],
    thumImg: '../../assets/image//private-jet.png',
    images: [
      // '../../assets/image//private-jet.png',
      '../../assets/image//private-jet-2.png',
      '../../assets/image//private-jet-3.png',
      '../../assets/image//private-jet-4.png',
    ],
    about: 'Join our Historical Walking Tour and explore the vibrant streets of Punda in Willemstad. Learn about the rich history, architecture, and culture of this UNESCO World Heritage site from our knowledgeable guides. This tour is perfect for history enthusiasts and offers plenty of opportunities for photography.',
    dothis: [
      'Explore historic streets.',
      'Learn about local history and culture.',
      'Visit key landmarks.',
      'Enjoy a guided tour with expert commentary.',
      'Capture memorable photos.',
    ],
    suitablefor: {
      group: true,
      single: true,
      children: true,
    },
    includeditems: {
      photos: false,
      video: false,
      water: true,
    },
    presentitems: {
      requiredequipment: false,
      chairs: false,
      wc: true,
    },
    availablelanguages: {
      english: true,
      dutch: true,
      papiamento: true,
      spanish: false,
    },
    whattobring: [
      'Comfortable shoes',
      'Hat',
      'Sunscreen',
    ],
    insights: 'Please wear comfortable shoes and bring water. The tour includes several stops for rest and photography.',
    knowbefore: [
      'Children under 5 must be accompanied by an adult.',
      'Wear comfortable walking shoes.',
      'Bring sunscreen and a hat.',
      'Tour may be canceled in case of bad weather.',
    ],
    sold: 300,
    revenue: 15000,
    reviews: 250,
    starRating: 4.4,
  },
  {
    id: 9,
    title: 'Stand-Up Paddleboarding (SUP)',
    category: 'Beaches',
    API: 'true',
    APIkey: 'bjvasuhg',
    APIurl: 'ww./kjkjbnsd/sdf',
    fetchAAPI: true,
    immediateBooking: true,
    location: 'Jan Thiel Beach, Curacao',
    price: 90.00,
    description: 'Try stand-up paddleboarding and enjoy a fun and relaxing activity on the water.',
    age: { min: '10', max: '55' },
    pickup: false,
    eticket: true,
    languages: ['English', 'Dutch', 'Papiamento'],
    badgeName: 'Fun',
    badge: 'Fun',
    rating: '4.7',
    camera: true,
    relatedTrips: ['paddleboarding', 'beach', 'relaxation'],
    thumImg: '../../assets/image//private-jet.png',
    images: [
      // '../../assets/image//private-jet.png',
      '../../assets/image//private-jet-2.png',
      '../../assets/image//private-jet-3.png',
      '../../assets/image//private-jet-4.png',
    ],
    about: 'Join our Stand-Up Paddleboarding (SUP) tour and enjoy a fun and relaxing activity on the water. Paddle along the beautiful coastline of Jan Thiel Beach and take in the stunning views. This tour is perfect for beginners and experienced paddlers alike and offers plenty of opportunities for photos and relaxation.',
    dothis: [
      'Paddle along the coastline.',
      'Relax on the water.',
      'Enjoy beautiful views.',
      'Learn from experienced instructors.',
      'Capture memorable moments.',
    ],
    suitablefor: {
      group: true,
      single: true,
      children: true,
    },
    includeditems: {
      photos: false,
      video: false,
      water: true,
    },
    presentitems: {
      requiredequipment: true,
      chairs: false,
      wc: true,
    },
    availablelanguages: {
      english: true,
      dutch: true,
      papiamento: true,
      spanish: false,
    },
    whattobring: [
      'Swimwear',
      'Towel',
      'Sunscreen',
    ],
    insights: 'Meet at the designated location 10 minutes before the tour starts. All equipment will be provided.',
    knowbefore: [
      'Children under 10 must be accompanied by an adult.',
      'Wear swimwear and bring a towel.',
      'Bring sunscreen and a hat.',
      'Tour may be canceled in case of bad weather.',
    ],
    sold: 140,
    revenue: 12600,
    reviews: 100,
    starRating: 4.7,
  },
  {
    id: 10,
    title: 'Cultural Cooking Class',
    category: 'Beaches',
    API: 'true',
    APIkey: 'bjvasuhg',
    APIurl: 'ww./kjkjbnsd/sdf',
    fetchAAPI: true,
    immediateBooking: true,
    location: 'Willemstad, Curacao',
    price: 110.00,
    description: 'Learn to cook traditional Curacaoan dishes with a hands-on cooking class.',
    age: { min: '8', max: '65' },
    pickup: false,
    eticket: true,
    languages: ['English', 'Dutch'],
    badgeName: 'Educational',
    badge: 'Educational',
    rating: '4.8',
    camera: true,
    relatedTrips: ['cooking', 'culture', 'food'],
    thumImg: '../../assets/image//private-jet.png',
    images: [
      // '../../assets/image//private-jet.png',
      '../../assets/image//private-jet-2.png',
      '../../assets/image//private-jet-3.png',
      '../../assets/image//private-jet-4.png',
    ],
    about: 'Join our Cultural Cooking Class and learn to cook traditional Curacaoan dishes. Our professional chefs will guide you through the process of creating delicious meals using local ingredients. This hands-on class is perfect for food enthusiasts and offers a unique cultural experience.',
    dothis: [
      'Learn to cook traditional dishes.',
      'Use fresh local ingredients.',
      'Enjoy a hands-on cooking experience.',
      'Taste your creations.',
      'Learn from professional chefs.',
    ],
    suitablefor: {
      group: true,
      single: true,
      children: true,
    },
    includeditems: {
      photos: false,
      video: false,
      water: true,
    },
    presentitems: {
      requiredequipment: true,
      chairs: true,
      wc: true,
    },
    availablelanguages: {
      english: true,
      dutch: true,
      papiamento: false,
      spanish: false,
    },
    whattobring: [
      'Comfortable clothing',
      'Appetite',
    ],
    insights: 'Meet at the designated location 10 minutes before the class starts. All ingredients and equipment will be provided.',
    knowbefore: [
      'Children under 8 must be accompanied by an adult.',
      'Wear comfortable clothing.',
      'Bring an appetite.',
      'Class may be canceled in case of bad weather.',
    ],
    sold: 160,
    revenue: 17600,
    reviews: 140,
    starRating: 4.8,
  },
];


const Trips = () => {
  const [selectedBooking, setSelectedBooking] = useState(null);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isAddTripModalVisible, setIsAddTripModalVisible] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [filterOption, setFilterOption] = useState('all');
  const [sortOption, setSortOption] = useState('none');
  const [autoCompleteOptions, setAutoCompleteOptions] = useState([]);
  const [bookings, setBookings] = useState(bookingsData);

  const showBookingDetails = (booking) => {
    setSelectedBooking(booking);
    setIsModalVisible(true);
  };

  const handleCloseModal = () => {
    setIsModalVisible(false);
    setSelectedBooking(null);
  };

  const handleSaveBooking = (updatedBooking) => {
    const updatedBookings = bookings.map((booking) =>
      booking.id === updatedBooking.id ? updatedBooking : booking
    );
    setBookings(updatedBookings);
    setIsModalVisible(false);
  };

  const handleSearchChange = (value) => {
    setSearchTerm(value);
    const filteredOptions = bookings
      .filter(booking => booking.title.toLowerCase().includes(value.toLowerCase()))
      .map(booking => ({ value: booking.title }));
    setAutoCompleteOptions(filteredOptions);
  };

  const filteredBookings = bookings
    .filter(booking =>
      booking.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
      booking.location.toLowerCase().includes(searchTerm.toLowerCase())
    )
    .filter(booking => {
      if (filterOption === 'all') return true;
      if (filterOption === 'sold') return booking.sold > 0;
      if (filterOption === 'immediateBooking') return booking.immediateBooking;
      return true;
    });

  const sortedBookings = filteredBookings.sort((a, b) => {
    if (sortOption === 'none') return 0;
    if (sortOption === 'priceHighLow') return b.price - a.price;
    if (sortOption === 'priceLowHigh') return a.price - b.price;
    if (sortOption === 'soldHighLow') return b.sold - a.sold;
    if (sortOption === 'soldLowHigh') return a.sold - b.sold;
    return 0;
  });

  const handleAddTrip = () => {
    setIsAddTripModalVisible(true);
  };

  const handleSaveNewTrip = (newTrip) => {
    setBookings([...bookings, { ...newTrip, id: bookings.length + 1 }]);
    setIsAddTripModalVisible(false);
  };

  const handleCloseAddTripModal = () => {
    setIsAddTripModalVisible(false);
  };

  const [blockedDates, setBlockedDates] = useState([
    new Date(2024, 7, 20),
    new Date(2024, 7, 21),
  ]);

  const disabledDate = (currentDate) => {
    return blockedDates.some(
      (date) =>
        currentDate.toISOString().split('T')[0] ===
        date.toISOString().split('T')[0]
    );
  };

  const addBlockedDate = (date) => {
    if (date && !blockedDates.some(d => d.getTime() === date.toDate().getTime())) {
      setBlockedDates([...blockedDates, date.toDate()]);
    }
  };

  const handleDateChange = (date) => {
    addBlockedDate(date);
  };

  const handleUnblockDate = (date) => {
    setBlockedDates(blockedDates.filter(d => d.getTime() !== date.getTime()));
  };
  return (
    <main className='ml-52 pl-3 pr-3'>
      <header className='w-full pt-10'>
        <h1 className='text-gray-800 font-medium text-xl'>Admin Controls</h1>
        <div className='flex justify-between w-full items-center'>
          <div className='flex gap-2'>
            <AutoComplete
              options={autoCompleteOptions}
              style={{ width: 200 }}
              onSelect={(value) => setSearchTerm(value)}
              onSearch={handleSearchChange}
            >
              <Input.Search placeholder="Search Activity" />
            </AutoComplete>
            <Select
              placeholder="Filter"
              onChange={(value) => setFilterOption(value)}
              className='w-40'
            >
              <Option value="all">All</Option>
              <Option value="sold">Sold</Option>
              <Option value="immediateBooking">Immediate Booking</Option>
            </Select>
            <Select
              placeholder="Sort By"
              onChange={(value) => setSortOption(value)}
              className='w-40'
            >
              <Option value="none">None</Option>
              <Option value="priceHighLow">Price: High to Low</Option>
              <Option value="priceLowHigh">Price: Low to High</Option>
              <Option value="soldHighLow">Sold: High to Low</Option>
              <Option value="soldLowHigh">Sold: Low to High</Option>
            </Select>
          </div>
          <button
            className='bluebutton p-2 pl-4 pr-4 rounded-sm text-white text-sm'
            onClick={handleAddTrip}
          >
            Add trip
          </button>
        </div>
      </header>

      <section className=' mt-6'>
        <div className=' grid grid-cols-4 gap-4 mt-2 text-gray-700'>
          {sortedBookings.map((booking) => (
            <div key={booking.id} className='bg-white rounded-xl shadow-sm overflow-hidden p-2 cursor-pointer' onClick={() => showBookingDetails(booking)}>
              <div className='relative'>
                <div className='bg-white p-2 text-theblue-400 rounded-lg shadow-lg absolute text-sm font-bold left-1 top-1'>
                  #{booking.id}
                </div>
                <img src={booking.thumImg} alt={booking.title} className='rounded-md w-full h-40 object-cover' />
                <div className='p-2 flex justify-between items-center relative'>
                  <h3 className='text-sm font-medium w-3/4'>{booking.title}</h3>
                  {/* <EditOutlined className='text-lg cursor-pointer text-gray-600' /> */}
                  <p className=' text-theblue-400 font-bold'>${booking.price}</p>
                </div>
              </div>
            </div>

          ))}
        </div>
      </section>

      <section className='mt-6 mb-6'>
        <div>
          <h1 className='text-gray-800 font-medium text-xl'>Block Dates</h1>
          <DatePicker
            variant='filled'
            className=' mt-3'
            disabledDate={disabledDate}
            onChange={handleDateChange}
            dateRender={(current) => {
              const style = {};
              if (blockedDates.some(
                (date) =>
                  current.toISOString().split('T')[0] ===
                  date.toISOString().split('T')[0]
              )) {
                style.border = '2px solid red';
                style.backgroundColor = '#ffe6e6';
                style.color = 'red';
              }
              return (
                <div className="ant-picker-cell-inner" style={style}>
                  {current.date()}
                </div>
              );
            }}
          />
          <div className=' mt-4'>
            <h4>Blocked Dates:</h4>
            <ul className='grid grid-cols-5 gap-2 mt-2'>
              {blockedDates.map((date, index) => (
                <li
                  key={index}
                  className='border text-red-500 border-red-400 bg-white bg-opacity-40 px-3 py-2 rounded-lg flex justify-between'
                >
                  {date.toDateString()}
                  <button onClick={() => handleUnblockDate(date)}>
                    <CloseCircleOutlined />
                  </button>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </section>

      {selectedBooking && (
        <BookingModal
          visible={isModalVisible}
          booking={selectedBooking}
          onClose={handleCloseModal}
          onSave={handleSaveBooking}
        />
      )}
      <AddTripModal
        visible={isAddTripModalVisible}
        onClose={handleCloseAddTripModal}
        onSave={handleSaveNewTrip}
      />
    </main>
  );
};

export default Trips;
