import React from 'react'
import { Rate } from 'antd';

function ReviewBox({ review }) {
  return (
    <div className='  rounded-2xl border border-gray-400 p-4 flex flex-col gap-5 mt-4 pb-10'>
      <div className=' flex justify-between'>
        <div className=' flex gap-2'>
          {/* <img src={review.profile} alt="" className=' w-12 h-12 rounded-full' /> */}
          <div>
            <p className='text-gray-800'>{review.name}</p>
            <p className=' text-sm text-gray-500'>{review.place}</p>
          </div>
        </div>
        <div className=' text-right '>
          <Rate disabled defaultValue={review.star} className=' w-25 -mr-5 scale-75' />
          <p className='text-xs md:text-sm text-gray-500'>{review.date}</p>
        </div>
      </div>

      <p className='text-sm md:text-base text-gray-600 font-light'>{review.testimonial}</p>
    </div>
  )
}

export default ReviewBox
