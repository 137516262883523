import React from 'react'
import SecondaryHeader from '../Components/SecondaryHeader';
import MainFooter from '../Components/MainFooter';

function HowWeWork() {
  return (
    <main>
      <SecondaryHeader />
      <section className='p-5 md:p-8 '>
        <h1 className=' text-3xl font-bold text-theblue-400'>How TheTripCuraçao works</h1>
        <p className='  font-extralight text-gray-500 text-sm md:text-lg '> When you book an experience with TheTripCuraçao, we provide the platform
          for discovering and booking various activities, but we do not provide the
          experiences directly.
          For detailed information on our Terms and Conditions, Disclaimer, Privacy
          Policy, and Cookie Policy, please visit their respective pages on our website.</p>


        <div className=' mt-4'>
          <h1 className=' font-medium text-theblue-400 md:text-lg'>What does TheTripCuraçao do?</h1>
          <p className='  font-extralight text-gray-500 text-sm md:text-lg '> We provide a platform for you to discover and book unique experiences in
            Curaçao.
            When you make a booking on our platform, you enter into a contract with the
            supplier—the person or entity providing the experience. The supplier is
            responsible for delivering the experience you book.
            Before being listed, suppliers enter into a contract with TheTripCuraçao to
            allow us to promote and make their experiences available for booking on our
            site and through third-party distribution channels. Experiences must meet our
            acceptance criteria to be listed, and and our quality standards to remain listed,
            on the platform.
            The experiences displayed on our platform are not reflective of all available
            experiences, whether online or offline.</p>
        </div>

        <div className=' mt-4'>
          <h1 className=' font-medium text-theblue-400 md:text-lg'>How does TheTripCuraçao make money?</h1>
          <p className='  font-extralight text-gray-500 text-sm md:text-lg '>We do not own any of the businesses listed on our site. When you make a
            booking, the supplier pays TheTripCuraçao a commission after you have
            completed the experience.</p>
        </div>

        <div className=' mt-4'>
          <h1 className=' font-medium text-theblue-400 md:text-lg'>What is the liability of the tripcuracao? (Read also terms and conditions)</h1>
          <p className=' mt-4 font-extralight text-gray-500 text-sm md:text-lg'>
            <span className='font-normal text-gray-800'>Third-Party Agreements:</span> TheTripCuraçao is not involved in the contractual agreements between users and tour operators and is therefore not responsible for any arrangements made between users and tour operators. In case of a conflict, users must resolve it directly with the tour operator. TheTripCuraçao will attempt to mediate but is not a party that can be held liable.
          </p>

          <p className=' mt-4 font-extralight text-gray-500 text-sm md:text-lg'>
            <span className='font-normal text-gray-800'>Direct Damage:</span> TheTripCuraçao is only liable for direct damage caused by gross negligence or willful misconduct by TheTripCuraçao, up to the maximum amount of the invoice or the deposit made through TheTripCuraçao, whichever is higher.
          </p>

          <p className=' mt-4 font-extralight text-gray-500 text-sm md:text-lg'>
            <span className='font-normal text-gray-800'>Information Accuracy:</span> TheTripCuraçao is not liable for damage resulting from any action or omission based on information available on the website or linked websites, even if there are inaccuracies.
          </p>

          <p className=' mt-4 font-extralight text-gray-500 text-sm md:text-lg'>
            <span className='font-normal text-gray-800'>Website Functionality:</span> TheTripCuraçao is not responsible for errors or irregularities in the functionality of the Website and is not liable for any downtime or unavailability of the Website for any duration.
          </p>

          <p className=' mt-4 font-extralight text-gray-500 text-sm md:text-lg'>
            <span className='font-normal text-gray-800'>Our approach to searching, sorting, ranking, and recommending:</span> We use various systems on the platform to rank and recommend experiences,
            allowing you to search for and sort what you see. These systems help present
            information to help you discover experiences we think you’ll enjoy.
          </p>
        </div>


        <div className=' mt-4'>
          <h1 className=' font-medium text-theblue-400 md:text-lg'>Search list pages</h1>
          <p className='  font-extralight text-gray-500 text-sm md:text-lg '>We offer you the ability to search for experiences and provide
            recommendations based on input in the search box, We might also
            personalize the search results based on experiences you have browsed or
            booked recently.
            <br />
            You can sort results in other ways, such as:
          


            <p className=' mt-4 font-extralight text-gray-500 text-sm md:text-lg'>
              <span className='font-normal text-gray-800'>Popularity:</span> Experiences are shown in order of popularity based on a combination of their rating, reviews, booking frequency, and overall sentiment.
            </p>

            <p className=' font-extralight text-gray-500 text-sm md:text-lg'>
              <span className='font-normal text-gray-800'>Price:</span> Experiences can be listed by lowest or highest price first.
            </p>

            <p className=' font-extralight text-gray-500 text-sm md:text-lg'>
              <span className='font-normal text-gray-800'>Duration:</span> Experiences can be ordered by their duration—either longest or shortest first.
            </p>

          </p>
          <p className=' mt-4 font-extralight text-gray-500 text-sm md:text-lg'>
          You can narrow down results using filters such as category, duratoin, price
          range, etc.
            </p>
        </div>
        <div className=' mt-4'>
          <h1 className=' font-medium text-theblue-400 md:text-lg'>Experience shelves</h1>
          <p className='  font-extralight text-gray-500 text-sm md:text-lg '>We do not own any of the businesses We present experiences in various ways on the platform, including horizontal
shelves. Often, the wording at the top of the shelf will explain the primary
basis on which experiences are shown, such as “Special Deals.” Experiences
that have special partnerships with TheTripCuraçao may also be featured in
additional places hereby enhancing their visibility.</p>
        </div>

        <div className=' mt-4'>
          <h1 className=' font-medium text-theblue-400 md:text-lg'>Reviews</h1>
          <p className='  font-extralight text-gray-500 text-sm md:text-lg '>You can only submit a review or rating of an experience if you made the
booking through TheTripCuraçao. Each review goes through an automated
tracking system before publication to ensure it meets our criteria. If the system
detects an issue, the review may be rejected, sent back to the reviewer for
validation, or manually reviewed by our content specialists. It usually takes
24–48 hours for reviews to appear on our site.
After publication, reviews may be checked if reported as not meeting our
criteria. Reviews written in exchange for personal benefits will be removed.

Suppliers can encourage customers to post reviews but cannot offer
incentives for doing so.</p>
        </div>

      </section>
      <MainFooter />
    </main>
  )
}

export default HowWeWork
