import React, { useState } from 'react';
import { ColorPicker, Upload, Input, Button, Select } from 'antd';
import { UploadOutlined } from '@ant-design/icons';

function EditContent() {
  const [color, setColor] = useState('#1677ff');
  const [heroTitle, setHeroTitle] = useState('Plan your Curacao trip today');
  const [heroImage, setHeroImage] = useState('../../assets/Hero-image.jpg');

  const handleImageChange = (info) => {
    if (info.file.status === 'done') {
      // Assuming your backend returns the URL of the uploaded image in the response
      const newImage = URL.createObjectURL(info.file.originFileObj); // Create a URL for the new image
      setHeroImage(newImage);
    }
  };
  const tripsoptions = [
    { label: 'Snorkeling', value: 'snorkeling' },
    { label: 'Hiking', value: 'hiking' },
    { label: 'Kayaking', value: 'kayaking' },
    { label: 'Scuba Diving', value: 'scuba_diving' },
    { label: 'Sunset Cruise', value: 'sunset_cruise' },
    { label: 'Mountain Biking', value: 'mountain_biking' },
    { label: 'Rock Climbing', value: 'rock_climbing' },
    { label: 'Zip Lining', value: 'zip_lining' },
    { label: 'Paragliding', value: 'paragliding' },
    { label: 'Skydiving', value: 'skydiving' },
    { label: 'Surfing', value: 'surfing' },
    { label: 'Windsurfing', value: 'windsurfing' },
    { label: 'Paddle Boarding', value: 'paddle_boarding' },
    { label: 'White Water Rafting', value: 'white_water_rafting' },
    { label: 'Fishing', value: 'fishing' },
    { label: 'Sailing', value: 'sailing' },
    { label: 'Jet Skiing', value: 'jet_skiing' },
    { label: 'Canoeing', value: 'canoeing' },
    { label: 'Horseback Riding', value: 'horseback_riding' },
    { label: 'Hot Air Balloon Ride', value: 'hot_air_balloon_ride' },
  ];

  return (
    <main className='ml-52 pl-3 pr-3'>
      <header className='w-full pt-10'>
        <h1 className='text-gray-800 font-medium text-xl'>Edit</h1>
      </header>
      <section className=' mt-10'>
        <div className='flex'>
          <div className='w-1/2 flex gap-8 justify-between'>
            <p className=' text-nowrap'>Hero Image</p>
            {/* Image with an upload button to change the image */}
            <div className='relative'>
              <img
                src={heroImage}
                alt="Hero"
                className='w-7/12 rounded-lg'
              />
              <Upload
                name="heroImage"
                showUploadList={false}
                customRequest={({ file, onSuccess }) => {
                  setTimeout(() => {
                    onSuccess("ok"); // Simulate success response from the server
                  }, 0);
                }}
                onChange={handleImageChange}
                className='absolute bottom-2 left-2 w-full'
              >
                <Button icon={<UploadOutlined />}>Change Image</Button>
              </Upload>
            </div>
          </div>
          <div className='w-1/2 flex flex-col gap-8'>
            <div className='flex justify-start items-center gap-4'>
              <p>Hero Title</p>
              {/* Input field to update hero title */}
              <Input
                value={heroTitle}
                onChange={(e) => setHeroTitle(e.target.value)}
                className='w-2/3'
              />
            </div>
            <div className='flex justify-start gap-4 items-center'>
              <p>Header Nav Color</p>
              {/* Color picker to update header nav color */}
              <ColorPicker value={color} onChange={setColor} />
            </div>
          </div>

        </div>
        <h1 className='text-gray-800 font-medium text-xl mt-10'>Edit sections</h1>
        <div className=' flex justify-between mt-4'>
          <p>Special deals:</p>
          <Select
            mode="multiple"
            allowClear
            style={{
              width: '70%',
            }}
            placeholder="Please select related trip"

            options={tripsoptions}
          />
        </div>
        <div className=' flex justify-between mt-4'>
          <p>Trending now:</p>
          <Select
            mode="multiple"
            allowClear
            style={{
              width: '70%',
            }}
            placeholder="Please select related trip"

            options={tripsoptions}
          />
        </div>
        <div className=' flex justify-between mt-4'>
          <p>Experience Curacao like a local:</p>
          <Select
            mode="multiple"
            allowClear
            style={{
              width: '70%',
            }}
            placeholder="Please select related trip"

            options={tripsoptions}
          />
        </div>
        <div className=' flex justify-between mt-4'>
          <p>Beaches:</p>
          <Select
            mode="multiple"
            allowClear
            style={{
              width: '70%',
            }}
            placeholder="Please select related trip"

            options={tripsoptions}
          />
        </div>
        <div className=' flex justify-between mt-4'>
          <p>Things to do:</p>
          <Select
            mode="multiple"
            allowClear
            style={{
              width: '70%',
            }}
            placeholder="Please select related trip"

            options={tripsoptions}
          />
        </div>
      </section>
      <section className=' mb-8'>
        <h1 className='text-gray-800 font-medium text-xl mt-10'>Edit footer details</h1>
        <div className=' flex justify-between mt-5'>
          <p>Chamber of commerce number:</p>
          <Input
            style={{
              width: '70%',
            }}
            defaultValue={'89766520'}></Input>
        </div>
        <div className=' flex justify-between mt-5'>
          <p>Legal
            name:</p>
          <Input style={{
            width: '70%',
          }}
            defaultValue={'Magnet Group'}></Input>
        </div>
        <div className=' flex justify-between mt-5'>
          <p>Email address: </p>
          <Input style={{
            width: '70%',
          }}
            defaultValue={'info@thetripcuracao.com'}></Input>
        </div>
        <div className=' flex justify-between mt-5'>
          <p>Contact: </p>
          <Input style={{
            width: '70%',
          }}
            defaultValue={'+31687205513'}></Input>
        </div>
        <div className=' w-full relative mt-3 pb-5'>
        <Button type='primary' className='absolute right-0'>Confrim Edit</Button>
        </div>
      </section>
    </main>
  );
}

export default EditContent;
