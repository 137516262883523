import React from 'react';
import { Route, Routes } from 'react-router-dom';
import AdminSidebar from '../Components/AdminSidebar';
import Trips from './Admin-controls-pages/Trips';
import Bookings from './Admin-controls-pages/Bookings';
import ReviewManagment from './Admin-controls-pages/ReviewManagment';
import EditContent from './Admin-controls-pages/EditContent';
import CompanyDirectory from './Admin-controls-pages/CompanyDirectory';
import DashboardPage from './Admin-controls-pages/DashBoard';
import UserManagement from './Admin-controls-pages/UserManagment';

import { EditOutlined, CloseCircleOutlined } from '@ant-design/icons';
import { Input, Select, AutoComplete, DatePicker, Button } from 'antd';

function Admin() {
  return (
    <div>
    <AdminSidebar/>
    
    <Routes>
      <Route path="/trips" element={<Trips />} />
      <Route path="/" element={<DashboardPage />} />
      <Route path="/booking" element={<Bookings />} />
      <Route path="/reviews" element={<ReviewManagment />} />
      <Route path="/editcontent" element={<EditContent />} />
      <Route path="/companydirectory" element={<CompanyDirectory />} />
      <Route path="/usermanagment" element={<UserManagement />} />
    </Routes>
    </div>
  );
}

export default Admin;
